<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/16/solid';
import { TextStyle, TextStyleFontSize, TextStyleShade } from '../TextStyle';

const props = withDefaults(defineProps<{
  /** The label for the stepper. */
  label: string;

  /** The maximum value of the stepper. */
  max: number;

  /** The minimum value of the stepper. */
  min: number;
}>(), {
  color: null,
  min: undefined,
  max: undefined,
});

const model = defineModel<number>();

function decrement(): void {
  if (model.value! <= props.min) {
    return;
  }

  model.value!--;
}

function increment(): void {
  if (model.value! >= props.max) {
    return;
  }

  model.value!++;
}

function handleFocus(event: Event): void {
  (event.target as HTMLInputElement).select();
}

function handleInput(event: InputEvent | Event): void {
  const target = event.target as HTMLInputElement;
  let value = Number(target.value);

  if (value > props.max) {
    value = props.max;
  }

  if (value < props.min) {
    value = props.min;
  }

  model.value = value;
  target.valueAsNumber = value;
}
</script>

<template>
  <div class="inline-flex select-none flex-col space-y-2">
    <div class="inline-flex items-stretch rounded border shadow-sm">
      <button
        :disabled="model === props.min"
        class="rounded-l bg-white px-3 hover:bg-slate-100 focus:z-10 disabled:pointer-events-none disabled:opacity-20"
        type="button"
        @click="decrement"
      >
        <ChevronLeftIcon class="size-4" />
      </button>
      <input
        :value="model"
        class="relative -my-px w-12 border border-slate-300 bg-slate-100 py-2 text-center ![appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        inputmode="numeric"
        type="number"
        @focus="handleFocus"
        @input="handleInput"
      >
      <button
        :disabled="model === props.max"
        class="rounded-r bg-white px-3 hover:bg-slate-100 focus:z-10 disabled:pointer-events-none disabled:opacity-20"
        type="button"
        @click="increment"
      >
        <ChevronRightIcon class="size-4" />
      </button>
    </div>
    <div class="select-text text-center text-xs">
      <TextStyle
        :font-size="TextStyleFontSize.Small"
        :shade="TextStyleShade.Subdued"
      >
        {{ label }}
      </TextStyle>
    </div>
  </div>
</template>
