<script lang="ts" setup>
import { Button, DescriptionItem, DescriptionList } from '@app/customer/Components';
import { App, type Types } from '@app/shared/types/generated-v2';
import { paymentMethodService } from '@app/customer/Services';
import { useApi } from '@app/customer/Composables/useApi';
import { useToast } from '@app/customer/Composables/useToast';
import { ToastVariant } from '@app/panel/Components';
import { useLoading } from '@app/customer/Composables/useLoading';
import { useI18n } from 'vue-i18n';

const props = defineProps<Types['App.Models.ViewModels.Customer.PaymentTransactionViewModel']>();

const { t } = useI18n();
const { emitToastEvent } = useToast();
const { isLoading, startLoading, stopLoading } = useLoading();
const { getPaymentMethodLogo } = paymentMethodService();

function generateReceipt(): void {
  startLoading();

  useApi()
    .post(route('api.payment-transaction.generate-receipt', { paymentTransaction: props.id }))
    .then((response) => window.open(response.data, '_blank'))
    .catch(() => fireCriticalToast())
    .finally(() => stopLoading());
}

function fireCriticalToast(): void {
  emitToastEvent({
    message: t('customer.global:error-message'),
    variant: ToastVariant.Critical,
  });
}
</script>

<template>
  <div>
    <div class="flex flex-wrap justify-between items-start gap-6">
      <DescriptionList>
        <DescriptionItem :term="$t('customer.status:payment-transaction:serial-number')">
          <div class="flex items-center mt-1 whitespace-nowrap">
            <img
              class="w-4 mr-1"
              :src="getPaymentMethodLogo(paymentMethod)"
              :alt="paymentMethod.systemName"
              loading="lazy"
            >
            <span>{{ id }}</span>
          </div>
        </DescriptionItem>
        <DescriptionItem :term="$t('customer.status:payment-transaction:amount')">
          {{ amount }}
        </DescriptionItem>
        <DescriptionItem
          v-if="status"
          :term="$t('customer.status:payment-transaction:status')"
        >
          {{ status }}
        </DescriptionItem>
      </DescriptionList>
      <Button
        v-if="status === App.Enums.PaymentStatusType.PAID"
        :loading="isLoading"
        @click="generateReceipt"
      >
        {{ $t('customer.status:payment-transaction:download-receipt') }}
      </Button>
    </div>
  </div>
</template>
