import { readonly, ref } from 'vue';
import UploadedFile from './UploadedFile';

export function useFileManager(amountLimit: number, fileSizeLimit: number, allowedFileTypes: string[]) {
  const error = ref<Record<string, string | null>>({});

  const uploadedFiles = ref<Record<string, UploadedFile[]>>({});

  function keyExists(key: string): boolean {
    return key in uploadedFiles.value;
  }

  function fileExists(key: string, id: string): boolean {
    return uploadedFiles.value[key].some((file) => file.id === id);
  }

  function isTypeAllowed(type: string): boolean {
    return allowedFileTypes.includes(type);
  }

  function isUnderAmountLimit(key: string): boolean {
    return uploadedFiles.value[key].length < amountLimit;
  }

  function isUnderFileSizeLimit(fileSize: number): boolean {
    return fileSize < fileSizeLimit;
  }

  function clearErrors(key: string): void {
    error.value[key] = null;
  }

  function addFiles(key: string, files: File[]): void {
    clearErrors(key);

    files.forEach((file) => {
      const uploadedFile = new UploadedFile(file);

      if (!keyExists(key)) {
        uploadedFiles.value[key] = [];
      }

      if (fileExists(key, uploadedFile.id)) {
        return;
      }

      if (!isUnderAmountLimit(key)) {
        error.value[key] = 'Allowed number of files is exceeded.';
        return;
      }

      if (!isTypeAllowed(uploadedFile.type)) {
        error.value[key] = `File type for ${uploadedFile.name} is not allowed.`;
        return;
      }

      if (!isUnderFileSizeLimit(uploadedFile.size)) {
        error.value[key] = `File ${uploadedFile.name} is too large.`;
        return;
      }

      uploadedFiles.value[key].push(uploadedFile);
    });
  }

  function removeFile(key: string, id: string): void {
    uploadedFiles.value[key] = uploadedFiles.value[key].filter((file) => file.id !== id);

    clearErrors(key);
  }

  function clearFiles(): void {
    uploadedFiles.value = {};
  }

  function setError(key: string, message: string): void {
    error.value[key] = message;
  }

  return {
    addFiles,
    error: readonly(error),
    uploadedFiles,
    removeFile,
    clearFiles,
    setError,
  };
}
