<script lang="ts" setup>
import { computed } from 'vue';
import { App } from '@app/shared/types/generated-v2';
import ReturnOrderStateProgressStep from './ReturnOrderStateProgressStep.vue';
import { ProgressStepStyle } from './types';
import ReturnOrderStateProgressLine from './ReturnOrderStateProgressLine.vue';

type ReturnOrderState = App.Enums.ReturnOrder.ReturnOrderState;

const states = App.Enums.ReturnOrder.ReturnOrderState;

const props = defineProps<{
  returnOrderState: ReturnOrderState;
  expectsShipments: boolean;
}>();

const newOptions = computed(() => {
  return props.expectsShipments
    ? [states.REQUEST_PENDING, states.REQUEST_APPROVED, states.RETURN_ANNOUNCED, states.RETURN_SHIPPED, states.RETURN_PROCESSING]
    : [states.REQUEST_PENDING, states.RETURN_PROCESSING];
});

const activeIndex = computed(() => {
  if (props.returnOrderState === states.RETURN_RESOLVED) {
    return newOptions.value.length;
  }

  return newOptions.value.findIndex((option) => option === props.returnOrderState);
});

function getStyleState(index: number): ProgressStepStyle {
  if (index < activeIndex.value) return 'filled';
  if (index > activeIndex.value) return 'pending';
  return 'active';
}

function getProgressPercentage(): number {
  return 100 / (newOptions.value.length - 1) * (activeIndex.value);
}
</script>

<template>
  <div
    class="relative isolate w-full items-center justify-center"
  >
    <div
      v-if="returnOrderState !== states.REQUEST_REJECTED"
      class="relative flex w-full items-center justify-between overflow-hidden"
      :class="{
        '!w-1/3': !expectsShipments
      }"
    >
      <ReturnOrderStateProgressStep
        v-for="(option, index) in newOptions"
        :key="index"
        :style="getStyleState(index)"
        :state="option"
        class="z-10"
      />
      <ReturnOrderStateProgressLine
        class="absolute left-0 top-1/2 -translate-y-1/2"
        :width="getProgressPercentage()"
      />
    </div>
  </div>
</template>
