<script lang="ts" setup>
import {
  computed,
} from 'vue';
import { InputLabel } from '../../InputLabel';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import { Spinner } from '../../Spinner';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  ariaControls: {
    type: String,
    default: null,
  },

  autoFocus: {
    type: Boolean,
    default: false,
  },

  focussed: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  labelFor: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  placeholder: {
    type: String,
    default: null,
  },

  maxLength: {
    type: Number,
    default: null,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

function onChanged(e: InputEvent): void {
  emit('update:modelValue', (e.target as HTMLFormElement).value);
}

const elementId = computed(() => props.id || useUniqueId('searchField'));
const widthClassObject = computed(() => ({
  'block w-full': props.fullWidth,
  'w-80 ': !props.fullWidth,
}));

const variantClassList = computed(() => {
  return [
    'border',
    'focus:outline-none',
    'focus:border-brand-500',
    'focus:ring-blue-300',
    'focus:ring-2',
    'border-slate-300',
  ];
});

function handlePaste(event: ClipboardEvent) {
  emit('update:modelValue', event.clipboardData.getData('text'));
}
</script>

<template>
  <InputLabel
    v-if="label"
    data-test="search-input-label"
    :label="label"
    :label-for="elementId"
  />

  <div
    data-test="search-input-wrapper"
    class="relative flex items-center"
  >
    <Spinner
      v-if="loading"
      data-test="search-input-spinner"
      class="absolute ml-3"
    />

    <MagnifyingGlassIcon
      v-else
      class="ml-3 absolute w-5 h-5"
    />

    <input
      :id="elementId"
      type="search"
      class="appearance-none rounded-md px-3 py-2 pr-3 pl-10 placeholder:text-slate-400 text-sm shadow-sm"
      :class="[widthClassObject, variantClassList]"
      :aria-controls="ariaControls"
      :aria-disabled="disabled"
      :autofocus="autoFocus"
      :disabled="disabled"
      :maxlength="maxLength"
      :placeholder="placeholder"
      autocomplete="off"
      tabindex="0"
      :value="modelValue"
      v-bind="$attrs"
      @input="onChanged"
      @paste.prevent="handlePaste"
    >
  </div>
</template>
