//@ts-nocheck
export namespace App.Enums {
  export enum ActionOptionType {
    'UNIQUE_TYPE' = 'unique-type',
    'UNIQUE_VALUE' = 'unique-value',
  }

  export enum BarcodeType {
    'RETURN_NUMBER' = 'return_number',
    'RETURN_TRACKING_NUMBER' = 'return_tracking_number',
    'SALES_ORDER_ID' = 'sales_order_id',
    'SALES_ORDER_NUMBER' = 'sales_order_number',
  }

  export enum BillingCycle {
    'ANNUAL' = 'annual',
    'MONTHLY' = 'monthly',
  }

  export enum BillingCycleOptions {
    'ANNUAL' = 'annual',
    'MONTHLY' = 'monthly',
  }

  export enum BulkActionRequestOrderType {
    'ADD_TAG' = 'add-tag',
    'APPROVE' = 'approve',
    'APPROVE_WITHOUT_SHIPMENT' = 'approve-without-shipment',
    'REJECT' = 'reject',
    'SET_STATUS' = 'set-status',
  }

  export enum BulkActionReturnOrderType {
    'SET_STATUS' = 'set-status',
    'ADD_TAG' = 'add-tag',
  }

  export enum ColorPalette {
    'Amber' = 'amber',
    'Blue' = 'blue',
    'Cyan' = 'cyan',
    'Emerald' = 'emerald',
    'Fuchsia' = 'fuchsia',
    'Gray' = 'gray',
    'Green' = 'green',
    'Indigo' = 'indigo',
    'Lime' = 'lime',
    'Orange' = 'orange',
    'Pink' = 'pink',
    'Purple' = 'purple',
    'Red' = 'red',
    'Rose' = 'rose',
    'Sky' = 'sky',
    'Slate' = 'slate',
    'Teal' = 'teal',
    'Violet' = 'violet',
    'Yellow' = 'yellow',
  }

  export enum ConditionField {
    'AMOUNT_OF_ITEMS' = 'amount_of_items',
    'CATEGORY' = 'category',
    'COST_PRICE' = 'cost_price',
    'COUNTRY' = 'country',
    'CUSTOMER_RETURN_AMOUNT' = 'customer_return_amount',
    'CUSTOMER_RETURN_AMOUNT_LAST_30_DAYS' = 'customer_return_amount_monthly',
    'CUSTOMER_RETURN_AMOUNT_LAST_365_DAYS' = 'customer_return_amount_yearly',
    'DELIVERY_AGE' = 'delivery_age',
    'DELIVERY_DATE' = 'delivery_date',
    'FORCED_RETURN_ADDRESS' = 'forced_return_address',
    'FULFILMENT_AGE' = 'fulfilment_age',
    'FULFILMENT_DATE' = 'fulfilment_date',
    'ORDER_AGE' = 'order_age',
    'PRODUCT_BRAND_NAME' = 'product_brand_name',
    'PRODUCT_DISCOUNT_AMOUNT' = 'product_discount_amount',
    'PRODUCT_DISCOUNT_PERCENTAGE' = 'product_discount_percentage',
    'PRODUCT_META_DATA' = 'product_meta_data',
    'PRODUCT_NAME' = 'product_name',
    'PRODUCT_PRICE' = 'product_price',
    'PRODUCT_SKU' = 'product_sku',
    'PRODUCT_SUPPLIER' = 'product_supplier',
    'QUANTITY_RETURNED' = 'quantity_returned',
    'QUANTITY_SOLD' = 'quantity_sold',
    'QUESTION_ANSWERED' = 'question_answered',
    'REQUEST_STATUS' = 'request_status',
    'RETURNED_AGE' = 'returned_age',
    'RETURNED_DATE' = 'returned_date',
    'RETURNED_ITEMS_TOTAL_WEIGHT' = 'returned_items_total_weight',
    'RETURN_ORDER_PRICE' = 'return_order_price',
    'RETURN_REASON' = 'return_reason',
    'RETURN_STATUS' = 'return_status',
    'RETURN_ORDER_TRACKING_NUMBER' = 'return_order_tracking_number',
    'SALES_ORDER_DATE' = 'sales_order_date',
    'SALES_ORDER_DISCOUNT_AMOUNT' = 'sales_order_discount_amount',
    'SALES_ORDER_DISCOUNT_CODE' = 'sales_order_discount_code',
    'SALES_ORDER_DISCOUNT_DESCRIPTION' = 'sales_order_discount_description',
    'SALES_ORDER_DISCOUNT_PERCENTAGE' = 'sales_order_discount_percentage',
    'SALES_ORDER_META_DATA' = 'sales_order_meta_data',
    'SALES_ORDER_NUMBER' = 'sales_order_number',
    'SALES_ORDER_PAYMENT_METHOD' = 'sales_order_payment_method',
    'SALES_ORDER_RETURNED_DISCOUNT_AMOUNT' = 'sales_order_returned_discount_amount',
    'SALES_ORDER_RETURNED_DISCOUNT_PERCENTAGE' = 'sales_order_returned_discount_percentage',
    'SALES_ORDER_SHIPPING_METHOD' = 'sales_order_shipping_method',
    'SALES_ORDER_STATUS' = 'sales_order_status',
    'SALES_ORDER_TRACKING_NUMBER' = 'sales_order_tracking_number',
    'SALES_ORDER_VALUE' = 'sales_order_value',
    'TAGS' = 'tags',
    'VALUE_OF_RETAINED_ITEMS' = 'value_of_retained_items',
  }

  export enum CouponType {
    'COUPON' = 'coupon',
    'GIFTCARD' = 'giftcard',
    'GIFTCARD_ACCOUNT' = 'giftcard_account',
  }

  export enum EmailTemplateParameterType {
    'CUSTOMER_NAME' = 'customer_name',
    'ORDER_NUMBER' = 'order_number',
    'RETURN_NUMBER' = 'return_number',
    'SHOP_NAME' = 'shop_name',
    'PAYMENT_REQUEST_DESCRIPTION' = 'payment_request_description',
    'PAYMENT_REQUEST_TOTAL' = 'payment_request_total',
    'PAYMENT_REQUEST_REFERENCE' = 'payment_request_reference',
    'COUPON_VALUE' = 'coupon_value',
    'COUPON_CODE' = 'coupon_code',
    'COUPON_EXPIRATION_DATE' = 'coupon_expiration_date',
    'NOTE' = 'note',
    'REQUEST_STATUS' = 'request_status',
    'RETURN_STATUS' = 'return_status',
    'VIEW_RETURN_BUTTON' = 'view_return_button',
    'VIEW_SHOP_BUTTON' = 'view_shop_button',
    'VIEW_PAYMENT_REQUEST_BUTTON' = 'view_payment_request_button',
  }

  export enum EntityLevel {
    'ITEM' = 'item',
    'RETURN' = 'return',
  }

  export enum FilterTabType {
    'REQUEST' = 'request',
    'RETURN' = 'return',
    'PRODUCT' = 'product',
    'OVERVIEW' = 'overview',
    'SHIPPING_METHOD' = 'shipping_method',
  }

  export enum FormDomainRouting {
    'ROUTE_SUB_DOMAIN' = 'route-sub-domain',
    'ROUTE_OWN_DOMAIN' = 'route-own-domain',
  }

  export enum FormNotificationType {
    'CONSUMER_EMAIL_SEND' = 'consumer-email-send',
    'DEPRECIATION_CREATED' = 'depreciation-created',
    'FAILED_AUTOMATION' = 'failed-automation',
    'NOTE_CREATED' = 'note-created',
    'NOTE_DELETED' = 'note-deleted',
    'NOTE_EDITED' = 'return-note-edited',
    'PAYMENT_REFUNDED' = 'payment-refunded',
    'PAYMENT_REQUEST_CREATED' = 'payment-request-created',
    'REQUEST_APPROVED' = 'request-approved',
    'REQUEST_APPROVED_WITHOUT_SHIPMENT' = 'request-approved-without-shipment',
    'REQUEST_APPROVED_WITH_MANUAL_SHIPMENT' = 'request-approved-with-manual-shipment',
    'REQUEST_CREATED' = 'request-created',
    'REQUEST_FREE_SHIPPING_CHANGED' = 'request-free-shipping-changed',
    'REQUEST_REJECTED' = 'request-rejected',
    'REQUEST_STATUS_CHANGED' = 'request-status-changed',
    'RETURN_COUPON_CREATED' = 'return-coupon-created',
    'RETURN_CREATED' = 'return-created',
    'RETURN_DELIVERED' = 'return-delivered',
    'RETURN_FINALIZED' = 'return-finalized',
    'RETURN_FULLY_RECEIVED' = 'return-fully-received',
    'RETURN_GIFT_CARD_CREATED' = 'return-gift-card-created',
    'RETURN_ORDER_REFUNDED' = 'return-order-refunded',
    'RETURN_PUSHED' = 'return-pushed',
    'RETURN_STATUS_CHANGED' = 'return-status-changed',
    'TAG_ADDED' = 'tag-added',
    'TAG_CREATED' = 'return-tag-created',
    'TAG_REMOVED' = 'return-tag-removed',
  }

  export enum OnboardingStateType {
    'HasReturnAddress' = 'hasReturnAddress',
    'HasReturnForm' = 'hasReturnForm',
    'HasReturnQuestions' = 'hasReturnQuestions',
    'HasReturnRules' = 'hasReturnRules',
    'HasIntegrations' = 'hasIntegrations',
    'HasTestReturn' = 'hasTestReturn',
  }

  export enum OrderValidationType {
    'Email' = 'email',
    'Postcode' = 'postcode',
    'CustomerId' = 'customerId',
  }

  export enum PaymentMethodType {
    'Card' = 'card',
    'SepaDebit' = 'sepa_debit',
  }

  export enum PaymentStatusType {
    'OPEN' = 'open',
    'CANCELED' = 'canceled',
    'PENDING' = 'pending',
    'AUTHORIZED' = 'authorized',
    'EXPIRED' = 'expired',
    'FAILED' = 'failed',
    'PAID' = 'paid',
  }

  export enum PaymentTransactionErrorType {
    'ERROR' = 'error',
    'BLACKLIST' = 'blacklist',
  }

  export enum PaymentTransactionLineType {
    'SHIPMENT' = 'shipment',
    'INSURANCE' = 'insurance',
    'PAYMENT_FEE' = 'payment_fee',
  }

  export enum PaymentTransactionType {
    'PAYMENT' = 'payment',
    'REFUND' = 'refund',
  }

  export enum PaywallAccessType {
    'DISABLE_ACCOUNT' = 'disable-account',
    'NO_LIMIT' = 'no-limit',
    'PAYMENT_WALL' = 'payment-wall',
  }

  export enum PermissionType {
    'REQUEST_ORDER_OVERVIEW' = 'request-order-overview',
    'REQUEST_ORDER_APPROVE' = 'request-order-approve',
    'REQUEST_ORDER_APPROVE_WITHOUT_SHIPMENT' = 'request-order-approve-without-shipment',
    'REQUEST_ORDER_REJECT' = 'request-order-reject',
    'REQUEST_ORDER_STATUS_MANAGE' = 'request-order-status-manage',
    'REQUEST_ORDER_NOTE_ADD' = 'request-order-note-add',
    'REQUEST_ORDER_DELETE' = 'request-order-delete',
    'RETURN_ORDER_DELETE' = 'return-order-delete',
    'RETURN_ORDER_DEPRECIATION_ADD' = 'return-order-depreciation-add',
    'RETURN_ORDER_ITEM_QUANTITY_MANAGE' = 'return-order-item-quantity-manage',
    'RETURN_ORDER_NOTE_ADD' = 'return-order-note-add',
    'RETURN_ORDER_OVERVIEW' = 'return-order-overview',
    'RETURN_ORDER_STATUS_MANAGE' = 'return-order-status-manage',
    'SHIPMENT_SET_SHIPMENTS_DELIVERED' = 'shipment-set-shipments-delivered',
    'FORM_CREATE' = 'form-create',
    'FORM_UPDATE' = 'form-update',
    'FORM_DUPLICATE' = 'form-duplicate',
    'FORM_DELETE' = 'form-delete',
    'FORM_EMAIL_NOTIFICATION_MANAGE' = 'form-email-notification-manage',
    'FORM_VISIBLE_PRODUCT_DETAILS' = 'form-visible-product-details',
    'FORM_LOGO_AND_LAYOUT_MANAGE' = 'form-logo-and-layout-manage',
    'FORM_RETURN_REASON_MANAGE' = 'form-return-reason-manage',
    'FORM_RETURN_QUESTION_MANAGE' = 'form-return-question-manage',
    'FORM_RETURN_RULE_MANAGE' = 'form-return-rule-manage',
    'FORM_SHIPPING_METHOD_MANAGE' = 'form-shipping-method-manage',
    'FORM_SHIPPING_SETTINGS' = 'form-shipping-settings',
    'FORM_INTEGRATION_MANAGE' = 'form-integration-manage',
    'FORM_DOMAIN_MANAGE' = 'form-domain-manage',
    'FORM_AUTOMATION_MANAGE' = 'form-automation-manage',
    'SETTING_LANGUAGE_MANAGE' = 'setting-language-manage',
    'SETTING_RETURN_NUMBER_FORMAT_MANAGE' = 'setting-return-number-format-manage',
    'SETTING_COUNTRY_MANAGE' = 'setting-country-manage',
    'SETTING_REQUEST_STATUS_MANAGE' = 'setting-request-status-manage',
    'SETTING_RETURN_STATUS_MANAGE' = 'setting-return-status-manage',
    'SETTING_TAG_MANAGE' = 'setting-tag-manage',
    'SETTING_CUSTOM_SMTP' = 'setting-custom-smtp',
    'INTEGRATION_ECOMMERCE_MANAGE' = 'integration-ecommerce-manage',
    'INTEGRATION_SHIPPING_MANAGE' = 'integration-shipping-manage',
    'INTEGRATION_API_KEYS_MANAGE' = 'integration-api-keys-manage',
    'INTEGRATION_WEBHOOKS_MANAGE' = 'integration-webhooks-manage',
    'TEMPLATE_EMAIL_MANAGE' = 'template-email-manage',
    'TEMPLATE_NOTE_MANAGE' = 'template-note-manage',
    'TEMPLATE_RETURN_INSTRUCTIONS_MANAGE' = 'template-return-instructions-manage',
    'TEMPLATE_SHIPPING_INSTRUCTIONS_MANAGE' = 'template-shipping-instructions-manage',
    'RETURN_ADDRESSES_MANAGE' = 'return-addresses-manage',
    'BILLING_VIEW' = 'billing-view',
    'BILLING_MANAGE_SUBSCRIPTIONS' = 'billing-manage',
    'BILLING_VIEW_INVOICES' = 'billing-view-invoices',
    'TEAM_MEMBERS_VIEW' = 'team-members-view',
    'TEAM_MEMBERS_INVITE' = 'team-members-invite',
    'TEAM_MEMBERS_REMOVE' = 'team-members-remove',
    'TEAM_MEMBERS_PERMISSIONS' = 'team-members-permissions',
    'PARTNER_MANAGEMENT' = 'partner-management',
    'ANALYTICS_OVERVIEW' = 'analytics-view',
    'ANALYTICS_PERFORMANCE' = 'analytics-performance',
    'ANALYTICS_PRODUCTS' = 'analytics-products',
  }

  export enum PlatformIntegrationAction {
    'CREATE_COUPON' = 'CreateCoupon',
    'CREDIT_INVOICE' = 'CreditInvoice',
    'GIFTCARD_ACCOUNT' = 'GiftCardAccount',
    'GIFTCARD' = 'GiftCard',
    'CREATE_REFUND' = 'CreateRefund',
    'CREATE_RETURN' = 'CreateReturn',
    'FINALIZE_RETURN' = 'FinalizeReturn',
  }

  export enum PlatformIntegrationDriver {
    'AdobeCommerce' = 'adobe-commerce',
    'Api2Cart' = 'api2cart',
    'BigCommerce' = 'big-commerce',
    'Bol' = 'bol',
    'CCVShop' = 'ccvshop',
    'ChannelEngine' = 'channel-engine',
    'Channable' = 'channable',
    'Demo' = 'demo',
    'Exact' = 'exact',
    'Goedgepickt' = 'goedgepickt',
    'ItsPerfect' = 'its-perfect',
    'Lightspeed' = 'lightspeed',
    'Magento2' = 'magento2',
    'Monta' = 'monta',
    'OIL' = 'oil',
    'Open' = 'open',
    'OracleNetsuite' = 'oracle-netsuite',
    'Picqer' = 'picqer',
    'PrestaShop' = 'prestashop',
    'QLS' = 'qls',
    'SalesForce' = 'salesforce',
    'Salesupply' = 'salesupply',
    'Sendcloud' = 'sendcloud',
    'Sendcloud3' = 'sendcloud3',
    'Sherpaan' = 'sherpaan',
    'Shopify' = 'shopify',
    'ShopifyPlus' = 'shopify-plus',
    'Shopware' = 'shopware',
    'Shopware5' = 'shopware5',
    'VirtualIntegration' = 'virtual-integration',
    'Wics' = 'wics',
    'WooCommerce' = 'woocommerce',
    'Pulpo' = 'pulpo',
    'XPRT' = 'xprt',
  }

  export enum PlatformIntegrationWebhookStatus {
    'ACTIVE' = 'active',
    'INACTIVE' = 'inactive',
    'UNSUPPORTED_EVENT' = 'unsupported',
  }

  export enum Preference {
    'Currency' = 'currency',
    'DateFormat' = 'date_format',
    'Locale' = 'locale',
    'ProductSorts' = 'products_sort',
    'ShippingMethodSorts' = 'shipping_method_sorts',
    'RequestsSort' = 'requests_sort',
    'ReturnsSorts' = 'returns_sort',
    'Timezone' = 'timezone',
  }

  export enum ProductDetailType {
    'BARCODE' = 'barcode',
    'BRAND' = 'brand',
    'COLOR' = 'color',
    'DISCOUNT' = 'discount',
    'PRICE' = 'price',
    'PRODUCT_ID' = 'product-id',
    'SIZE' = 'size',
    'SKU' = 'sku',
  }

  export enum ProductType {
    'SIMPLE' = 'simple',
    'VARIANT' = 'variant',
    'BUNDLE' = 'bundle',
  }

  export enum RefundStatusType {
    'QUEUED' = 'queued',
    'PENDING' = 'pending',
    'PROCESSING' = 'processing',
    'REFUNDED' = 'refunded',
    'FAILED' = 'failed',
    'CANCELED' = 'canceled',
  }

  export enum ReturnAddressType {
    'RETURN_ADDRESS' = 'return_address',
    'STORE_LOCATION' = 'store_location',
  }

  export enum ReturnConditionType {
    'AUTO_COMPLETE' = 'auto_complete',
    'COUNTRY_SELECT' = 'country_select',
    'CURRENCY_SELECT' = 'currency_select',
    'DATE_TIME' = 'date_time',
    'DIMENSION' = 'dimension',
    'META_DATA' = 'meta_data',
    'NUMBER' = 'number',
    'PRICE' = 'price',
    'QUESTION' = 'question',
    'REQUEST_STATUS' = 'request_status',
    'RETURN_ADDRESS_SELECT' = 'return_address_select',
    'RETURN_REASON' = 'return_reason',
    'RETURN_STATUS' = 'return_status',
    'TAGS' = 'tags',
    'TEXT' = 'text',
    'WEIGHT' = 'weight',
  }

  export enum ReturnImporterDriver {
    'CHANNEL_ENGINE' = 'channel-engine',
  }

  export enum ReturnOrderIntentStatus {
    'COMPLETED' = 'completed',
    'FAILED' = 'failed',
    'PENDING' = 'pending',
  }

  export enum ReturnOrderState {
    'RequestPending' = 'request-pending',
    'RequestApproved' = 'request-approved',
    'RequestRejected' = 'request-rejected',
    'ReturnWithoutShipment' = 'return-without-shipment',
    'Return' = 'return',
  }

  export enum ReturnOrderType {
    'REFUND' = 'refund',
    'VOUCHER' = 'voucher',
    'REPAIR' = 'repair',
    'REPLACE' = 'replace',
    'EXCHANGE' = 'exchange',
  }

  export enum ReturnQuestionFieldType {
    'Checkbox' = 'checkbox',
    'DropdownMulti' = 'dropdownMulti',
    'DropdownSingle' = 'dropdownSingle',
    'File' = 'file',
    'Radio' = 'radio',
    'TextArea' = 'textarea',
    'TextInput' = 'text',
    'IbanInput' = 'ibanTextField',
  }

  export enum ReturnQuestionLevel {
    'OrderItem' = 'order-item',
    'Order' = 'order',
  }

  export enum ReturnRuleActionTrigger {
    'ReturnRequest' = 'return-request',
    'FreeReturn' = 'free-return',
    'Reject' = 'reject',
    'ApproveWithoutShipment' = 'approve-without-shipment',
  }

  export enum ReturnRuleActionType {
    'ADD_TAG' = 'add-tag',
    'MARK_ORDER_AS' = 'mark-order-as',
    'SET_REQUEST_STATUS' = 'set-request-status',
    'SET_RETURN_ADDRESS' = 'set-return-address',
    'SET_RETURN_ORDER_STATE' = 'set-return-order-state',
    'SET_RETURN_STATUS' = 'set-return-status',
    'HIDE_ORDER_ITEM' = 'hide-order-item',
    'REJECT_ORDER_ITEM' = 'reject-order-item',
    'FORCE_RETURN_ADDRESS_FOR_ORDER_ITEM' = 'force-return-address-for-order-item',
  }

  export enum ReturnRuleTrigger {
    'ORDER_EVALUATED' = 'order-evaluated',
    'RETURN_CREATED' = 'return-created',
  }

  export enum SettlementImportStatusType {
    'FAILED' = 'failed',
    'RUNNING' = 'running',
    'SUCCESS' = 'success',
  }

  export enum ShipmentDocumentType {
    'AIR_WAYBILL' = 'air-waybill',
    'CN23_DEFAULT' = 'cn23-default',
    'CN32' = 'cn23',
    'COMMERCIAL_INVOICE' = 'commercial-invoice',
    'CP71' = 'cp71',
    'LABEL' = 'label',
    'QR' = 'qr',
  }

  export enum ShipmentInvestigationRequestType {
    'SHIPMENT_DELAYED' = 'shipment-delayed',
    'SHIPMENT_DELIVERED_NOT_RECEIVED' = 'shipment-delivered-not-received',
    'SHIPMENT_DAMAGED' = 'shipment-damaged',
    'SHIPMENT_LOST' = 'shipment-lost',
    'OTHER' = 'other',
  }

  export enum ShipmentStatus {
    'ADDRESS_INVALID' = 'address-invalid',
    'ANNOUNCED' = 'announced',
    'ANNOUNCED_NOT_COLLECTED' = 'announced-not-collected',
    'ANNOUNCEMENT_FAILED' = 'announcement-failed',
    'AT_CUSTOMS' = 'at-customs',
    'AT_SORTING_CENTER' = 'at-sorting-center',
    'AWAITING_WEBSHOP_PICKUP' = 'awaiting-webshop-pickup',
    'BEING_ANNOUNCED' = 'being-announced',
    'BEING_SORTED' = 'being-sorted',
    'CANCELLED' = 'cancelled',
    'CANCELLATION_REQUESTED' = 'cancellation-requested',
    'DELIVERED' = 'delivered',
    'DELIVERY_ADDRESS_CHANGED' = 'delivery-address-changed',
    'DELIVERY_ATTEMPT_FAILED' = 'delivery-attempt-failed',
    'DELIVERY_DATE_CHANGED' = 'delivery-date-changed',
    'DELIVERY_DELAYED' = 'delivery-delayed',
    'DELIVERY_METHOD_CHANGED' = 'delivery-method-changed',
    'DROPPED_OFF' = 'dropped-off',
    'EN_ROUTE_TO_SORTING_CENTER' = 'en-route-to-sorting-center',
    'ERROR_COLLECTING' = 'error-collecting',
    'EXCEPTION' = 'exception',
    'NO_LABEL' = 'no-label',
    'NOT_SORTED' = 'not-sorted',
    'PARCEL_EN_ROUTE' = 'parcel-en-route',
    'REFUSED_BY_RECIPIENT' = 'refused-by-recipient',
    'RETURNED_TO_SENDER' = 'returned-to-sender',
    'SHIPMENT_COLLECTED_BY_WEBSHOP' = 'shipment-collected-by-webshop',
    'SHIPMENT_PICKED_UP_BY_DRIVER' = 'shipment-picked-up-by-driver',
    'SORTED' = 'sorted',
    'UNABLE_TO_DELIVER' = 'unable-to-deliver',
    'UNKNOWN_STATUS' = 'unknown-status',
  }

  export enum ShipmentType {
    'Locker' = 'locker',
    'ParcelShop' = 'parcel-shop',
    'Pickup' = 'pickup',
  }

  export enum ShippingIntegrationDriver {
    'BPOST' = 'bpost',
    'Budbee' = 'budbee',
    'COLISSIMO' = 'colissimo',
    'CORREOS' = 'correos',
    'Custom' = 'custom',
    'DHL' = 'dhl',
    'DHL_EXPRESS' = 'dhl-express',
    'DHL_PAKET' = 'dhl-paket',
    'DHL_SENDCLOUD' = 'dhl_sendcloud',
    'DPD' = 'dpd',
    'DPD_SENDCLOUD' = 'dpd_sendcloud',
    'DPD_UK' = 'dpd_uk',
    'EASYPOST' = 'easypost',
    'FEDEX' = 'fedex',
    'FEDEX_UK' = 'fedex_uk',
    'GLS_DE_AT_BE_FR' = 'gls_de_at_be_fr',
    'GLS_ES' = 'gls_es',
    'GLS_NL' = 'gls_nl',
    'HERMES' = 'hermes',
    'Homerr' = 'homerr',
    'Monta' = 'monta',
    'POSTE_ITALIANE' = 'poste-italiane',
    'POST_AT' = 'post_at',
    'POST_NL_BE_SENDCLOUD' = 'post_nl_be_sendcloud',
    'POST_NL_SENDCLOUD' = 'post_nl_sendcloud',
    'EVRI' = 'evri',
    'MONDIAL_RELAY' = 'mondial_relay',
    'PostNL' = 'postnl',
    'PostNL_BE' = 'postnl_be',
    'QLS' = 'qls',
    'RETURN_IN_STORE' = 'return_in_store',
    'ROYAL_MAIL' = 'royal_mail',
    'SENDCLOUD' = 'sendcloud',
    'SEUR' = 'seur',
    'SPRING' = 'spring',
    'UPS' = 'ups',
  }

  export enum ShippingLabelReferenceType {
    'SALES_ORDER_ID' = 'sales_order_id',
    'SALES_ORDER_NUMBER' = 'sales_order_number',
    'RETURN_NUMBER' = 'return_number',
    'TRACKING_NUMBER' = 'tracking_number',
    'RETURN_ORDER_METADATA' = 'return_order_metadata',
  }

  export enum ShippingMethodType {
    'DropOff' = 'drop-off',
    'Manual' = 'manual',
    'Other' = 'other',
    'PickUp' = 'pick-up',
    'ReturnInStore' = 'return-in-store',
  }

  export enum StripeCancellationFeedbackType {
    'TOO_EXPENSIVE' = 'too_expensive',
    'MISSING_FEATURES' = 'missing_features',
    'SWITCHED_SERVICE' = 'switched_service',
    'UNUSED' = 'unused',
    'CUSTOMER_SERVICE' = 'customer_service',
    'TOO_COMPLEX' = 'too_complex',
    'LOW_QUALITY' = 'low_quality',
    'OTHER' = 'other',
  }

  export enum SubscriptionPlanAmount {
    'AMOUNT_100' = 100,
    'AMOUNT_200' = 200,
    'AMOUNT_300' = 300,
    'AMOUNT_500' = 500,
    'AMOUNT_750' = 750,
    'AMOUNT_1000' = 1000,
    'AMOUNT_1500' = 1500,
    'AMOUNT_2250' = 2250,
    'AMOUNT_3000' = 3000,
    'AMOUNT_4500' = 4500,
    'AMOUNT_6000' = 6000,
    'AMOUNT_7500' = 7500,
    'AMOUNT_10000' = 10000,
    'AMOUNT_12500' = 12500,
    'AMOUNT_15000' = 15000,
    'AMOUNT_17500' = 17500,
    'AMOUNT_20000' = 20000,
    'AMOUNT_22500' = 22500,
    'AMOUNT_25000' = 25000,
    'AMOUNT_27500' = 27500,
    'AMOUNT_30000' = 30000,
    'AMOUNT_32500' = 32500,
    'AMOUNT_35000' = 35000,
  }

  export enum SubscriptionPlanFeatures {
    'ADD_YOUR_OWN_CARRIER_CONTRACTS' = 'add-your-own-carrier-contracts',
    'AUTOMATIONS' = 'automations',
    'COUPONS' = 'coupons',
    'GLOBAL_SHIPPING_METHODS' = 'global-shipping-methods',
    'GRADING' = 'grading',
    'INTEGRATIONS' = 'integrations',
    'MACHINE_LEARNING_INSIGHTS' = 'machine-learning-insights',
    'MULTI_LANGUAGE_CURRENCY' = 'multi-language-&-currency',
    'ONBOARDING_SPECIALIST' = 'onboarding-specialist',
    'OPEN_API' = 'open-api',
    'PAID_RETURNS' = 'paid-returns',
    'PAPERLESS_RETURNS' = 'paperless-returns',
    'PAYMENTS_REQUESTS' = 'payments-requests',
    'PERFORMANCE_ANALYTICS' = 'performance-analytics',
    'REALTIME_NOTIFICATIONS' = 'realtime-notifications',
    'REFUNDS' = 'refunds',
    'REMOVE_BRANDING' = 'remove-branding',
    'RETURN_ADDRESSES' = 'return-addresses',
    'RETURN_ANALYTICS' = 'return-analytics',
    'RETURN_ANNOUNCEMENTS' = 'return-announcements',
    'RETURN_DASHBOARD' = 'return-dashboard',
    'RETURN_FORMS' = 'return-forms',
    'RETURN_PANEL' = 'return-panel',
    'RETURN_QUESTIONS' = 'return-questions',
    'RETURN_REQUESTS' = 'return-requests',
    'RETURN_RULES' = 'return-rules',
    'SHIPPING_ANALYTICS' = 'shipping-analytics',
    'SHIPPING_INSTRUCTIONS' = 'shipping-instructions',
    'STORE_LOCATIONS' = 'store-locations',
    'SUPPORT' = 'support',
  }

  export enum SubscriptionPlanOption {
    'AUTOMATE' = 'automate',
    'INTEGRATE' = 'integrate',
    'OPERATE' = 'operate',
  }

  export enum TaxVerificationState {
    'PENDING' = 'pending',
    'VERIFIED' = 'verified',
    'UNVERIFIED' = 'unverified',
    'UNAVAILABLE' = 'unavailable',
  }

  export enum ToastNotificationVariant {
    'Critical' = 'critical',
    'Info' = 'info',
    'Success' = 'success',
    'Warning' = 'warning',
  }

  export enum TwoFactorStatus {
    'ENABLED' = 'Enabled',
    'DISABLED' = 'Disabled',
  }
}
export namespace App.Integrations.Platform.Drivers.WooCommerce.Enums {
  export enum ConnectionType {
    'RETURNLESS_PLUGIN' = 'returnless_plugin',
    'WOOCOMMERCE_NATIVE' = 'woocommerce_native',
  }
}
export namespace App.Services.Integration.PlatformService.Drivers.Salesupply.Enums {
  export enum ConnectionType {
    'OMS' = 'oms',
    'CMS' = 'cms',
  }
}
export namespace App.Enums.Automations {
  export enum CreateCouponValueType {
    'DO_NOT_REFUND' = 'do-not-refund',
    'ALWAYS_REFUND' = 'always-refund',
    'ONLY_REFUND_FULLY_RETURNED_ORDER' = 'only-refund-fully-returned-order',
  }

  export enum CreateRefundValueType {
    'DO_NOT_REFUND' = 'do-not-refund',
    'ALWAYS_REFUND' = 'always-refund',
    'ONLY_REFUND_FULLY_RETURNED_ORDER' = 'only-refund-fully-returned-order',
  }
}
export namespace App.Enums.ReturnOrder {
  export enum ReturnOrderState {
    'REQUEST_PENDING' = 'request-pending',
    'REQUEST_APPROVED' = 'request-approved',
    'REQUEST_REJECTED' = 'request-rejected',
    'RETURN_ANNOUNCED' = 'return-announced',
    'RETURN_PROCESSING' = 'return-processing',
    'RETURN_RESOLVED' = 'return-resolved',
    'RETURN_SHIPPED' = 'return-shipped',
  }
}
export namespace App.Services.Integration.ShippingService.Drivers.Monta.Enums {
  export enum ShippingCarrier {
    'DPD' = 'DPD',
    'POST_NL' = 'PostNL',
    'UPS' = 'UPS',
    'DHL' = 'DHL',
  }
}
export type Types = {
  'App.Models.ViewModels.Analytics.AnalyticsFormQuestionAnswerViewModel': {
    answer: string;
    count: number;
  };
  'App.Models.ViewModels.Analytics.AnalyticsFormQuestionsAnswersViewModel': {
    formId: string;
    formName: string;
    questions: Array<App.Models.ViewModels.Analytics.AnalyticsFormQuestionsViewModel>;
  };
  'App.Models.ViewModels.Analytics.AnalyticsFormQuestionsViewModel': {
    question: string;
    type: string;
    answers: Array<App.Models.ViewModels.Analytics.AnalyticsFormQuestionAnswerViewModel>;
  };
  'App.Models.ViewModels.AttachmentViewModel': {
    id: string;
    path: string;
    filename: string;
    filesize: number;
  };
  'App.Models.ViewModels.Customer.AttachmentViewModel': {
    id: string;
    path: string;
    filename: string;
    filesize: number;
  };
  'App.Http.ViewModels.Panel.Analytics.BarChartViewModel': {
    labels: { [key: number]: string };
    datasets: { [key: number]: any };
  };
  'App.Http.ViewModels.Customer.BarcodeViewModel': {
    barcode: string;
    content: string;
    outputType: any;
  };
  'App.Http.ViewModels.Panel.Account.BillingViewModel': {
    tenant: App.Models.ViewModels.TenantViewModel;
    currentPeriodUsage: number;
    showUsage: boolean;
    getCurrentPeriodStartDate: any | null;
    hasSubscription: boolean;
    isCustomSubscription: boolean;
    showSubscriptionPlan: boolean;
    currentSubscriptionName: string | null;
    currentSubscriptionBillingCycle: App.Enums.BillingCycle | null;
    currentSubscriptionPrice: string | null;
    currentSubscriptionLabel: string | null;
    suggestedPlanIdentifier: string;
    suggestedPlanLabel: string;
    hasExceededPlan: boolean;
    currentSubscriptionEndDate: string | null;
    paymentMethods: Array<App.Models.ViewModels.StripePaymentMethodViewModel>;
    invoices: Array<App.Models.ViewModels.InvoiceViewModel>;
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    plans: Array<App.Models.ViewModels.SubscriptionPlanViewModel>;
    subscriptionGracePeriod: string;
    stripeCancellationFeedbackTypes: Array<App.Enums.StripeCancellationFeedbackType>;
    currentProduct: App.Models.ViewModels.SubscriptionProductViewModel | null;
    endOfBillingDate: string | null;
    billingCycle: App.Enums.BillingCycle;
    billingCycleOptions: Array<App.Models.ViewModels.SubscriptionToggleViewModel>;
    amount: number;
    amountOptionsMonthly: Array<App.Models.ViewModels.SelectOptionViewModel>;
    amountOptionsAnnual: Array<App.Models.ViewModels.SelectOptionViewModel>;
    documents: any;
    features: {
      [key: string]: {
        [key: string]: { label: string | null; check: boolean | null };
      };
    };
  };
  'App.Models.ViewModels.CardStripePaymentMethodViewModel': {
    brand: any;
    expiration: string;
    last4: string;
  };
  'App.Models.ViewModels.CarrierViewModel': {
    id: string;
    name: string;
    logo: { name: string; imageSourceUrl: string };
  };
  'App.Models.ViewModels.Customer.CarrierViewModel': {
    id: string;
    name: string;
    logo: { name: string; imageSourceUrl: string };
  };
  'App.Models.ViewModels.CategoryViewModel': { id: string; name: string };
  'App.Models.ViewModels.CombinedProductViewModel': {
    id: string;
    externalId: string;
    sku: string;
    barcode: string | null;
    name: string | null;
    width: number;
    length: number;
    height: number;
    hsCode: string | null;
    brand: string | null;
    categories: Array<App.Models.ViewModels.CategoryViewModel>;
    supplier: string | null;
    imageSource: string | null;
    url: string | null;
  };
  'App.Http.ViewModels.Customer.CombinedProductViewModel': {
    id: string;
    externalId: string;
    sku: string;
    barcode: string | null;
    name: string | null;
    width: number | null;
    length: number | null;
    height: number | null;
    hsCode: string | null;
    brand: string | null;
    categories: Array<App.Models.ViewModels.CategoryViewModel>;
    supplier: string | null;
    imageSource: string | null;
    url: string | null;
    parent: App.Http.ViewModels.Customer.ProductViewModel | null;
  };
  'App.Http.ViewModels.Customer.CombinedSalesOrderItemViewModel': {
    id: string;
    product: App.Http.ViewModels.Customer.CombinedProductViewModel;
    productName: string | null;
    externalId: string;
    extensionAttributes: Array<any> | null;
    quantity: number;
    currency: string;
    discount: any;
    price: any;
    rowDiscount: string;
    returnedQuantity: number;
    returnableQuantity: number;
    rowTotalPrice: string;
    itemCost: any;
    vatCode: string | null;
    vatRate: number;
    hasReturnOrderItems: boolean;
    isReturnable: boolean;
    metaData: { [key: string]: any };
    returnAddressIds: Array<string>;
  };
  'App.Models.ViewModels.CountryViewModel': {
    id: string;
    name: string;
    isoCode: string;
    currencyCode: string;
    currencySymbol: string;
    vatPercentage: number;
    hasStates: boolean;
    states: Array<{ code: string; state: string }>;
    supportsPaidReturns: boolean;
  };
  'App.Models.ViewModels.Customer.CountryViewModel': {
    id: string;
    name: string;
    localeName: string;
    isoCode: string;
    currencyCode: string;
    currencySymbol: string;
    hasStates: boolean;
    states: Array<{ code: string; state: string }>;
  };
  'App.Models.ViewModels.CouponViewModel': {
    id: string;
    amount: string;
    code: string | null;
    type: App.Enums.CouponType | null;
    applyToShipping: boolean;
    freeShipping: boolean;
    createdAt: string;
    expiresAt: string | null;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.CreateFormAutomationsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    formAutomationPlatformIntegrationOptions: Array<App.Models.ViewModels.ActionOptions.FormAutomationPlatformIntegrationOptionViewModel>;
    returnStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    requestStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    returnInstructions: Array<App.Models.ViewModels.ReturnInstructionViewModel>;
    triggerOptions: {
      [key: number]: App.Models.ViewModels.FormAutomationTriggerOptionViewModel;
    };
    actionOptions: { [key: string]: { [key: number]: any } };
    conditionTypeMap: { [key: string]: string };
    availableConditionTypes: { [key: string]: { [key: string]: string } };
    returnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.CreateGeneralFormViewModel': {
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.CreateReturnQuestionViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    availableFieldTypes: Array<string>;
    conditionTypeMap: { [key: string]: string };
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    returnOrderQuestions: { [key: string]: { [key: string]: any } };
    returnOrderItemQuestions: { [key: string]: { [key: string]: any } };
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    availableConditionTypesByLevel: { [key: string]: any };
    tags: Array<App.Models.ViewModels.TagViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.CreateReturnRuleViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    returnRuleLevel: App.Enums.EntityLevel;
    conditionTypeMap: { [key: string]: string };
    availableConditionTypes: { [key: string]: string };
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    returnInstructions: Array<App.Models.ViewModels.ReturnInstructionViewModel>;
    actionOptions: { [key: number]: { [key: string]: any } };
    returnAddressesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    returnStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    requestStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    returnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.CreateShippingContractViewModel': {
    shippingIntegration: App.Models.ViewModels.ShippingIntegrationViewModel;
    formStructure: Array<any>;
    countries: Array<App.Models.ViewModels.CountryViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.GeneralSettings.CustomSmtpViewModel': {
    isCustomSmtpEnabled: boolean;
    host: string | null;
    port: string | null;
    username: string | null;
    password: string | null;
    connectionEstablished: boolean | null;
  };
  'App.Models.ViewModels.CustomerAddressViewModel': {
    id: string;
    country: App.Models.ViewModels.CountryViewModel;
    telephone: string | null;
    street: string;
    houseNumber: string;
    suffix: string;
    postalCode: string;
    city: string;
    state: string | null;
    latitude: string | null;
    longitude: string | null;
  };
  'App.Models.ViewModels.CustomerViewModel': {
    id: string;
    externalId: string | null;
    email: string;
    name: string | null;
    addresses: Array<App.Models.ViewModels.CustomerAddressViewModel>;
    createdAt: string;
    updatedAt: string;
  };
  'App.Models.ViewModels.Customer.CustomerViewModel': {
    id: string;
    email: string;
    name: string | null;
    addresses: Array<App.Models.ViewModels.CustomerAddressViewModel>;
    createdAt: string;
    updatedAt: string;
  };
  'App.Http.ViewModels.Panel.DashboardViewModel': {
    tenant: App.Models.ViewModels.TenantViewModel;
    onboardingStatus: {
      hasReturnAddress: boolean;
      hasReturnForm: boolean;
      hasReturnQuestions: boolean;
      hasReturnRules: boolean;
      hasTestReturn: boolean;
    };
  };
  'App.Http.ViewModels.Panel.Analytics.DataPoint': {
    toArray: Array<any>;
    label: string;
    value: number;
  };
  'App.Models.ViewModels.Analytics.DateAnalyticsFilterViewModel': {
    from: string;
    to: string;
  };
  'App.Http.ViewModels.Customer.DisplayOrderViewModel': {
    orderNumber: string | null;
    validationValue: string | null;
    validationMethod: string;
    orderDate: string | null;
    previousIntents: Array<App.Models.ViewModels.Customer.PreviousIntentViewModel>;
    visibleProductDetails: Array<App.Enums.ProductDetailType>;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<any>;
    requestData: { name: string; order_data: any; address_data: any };
    confirmRoute: string;
    questions: Array<App.Models.ViewModels.ReturnFormQuestionViewModel>;
    reasons: Array<App.Models.ViewModels.Customer.ReturnReasonViewModel>;
    platformIntegrationDriver: App.Enums.PlatformIntegrationDriver | null;
    salesOrder: App.Http.ViewModels.Customer.SalesOrderViewModel | null;
    productNameEnabled: boolean;
    skuEnabled: boolean;
    sizeEnabled: boolean;
    colorEnabled: boolean;
    returnReasonEnabled: boolean;
    isRandomReturnReasonsOrder: boolean;
    returnOrderIntentId: string;
    dynamicReturnCostsPerItem: number | null;
    dynamicReturnCostsStartFrom: number;
    dynamicReturnCostsInstruction: string | null;
  };
  'App.Models.ViewModels.DomainViewModel': { id: string; domain: string };
  'App.Models.ViewModels.Customer.DomainViewModel': {
    id: string;
    domain: string;
  };
  'App.Http.ViewModels.Customer.EditAddressViewModel': {
    returnOrderIntent: App.Models.ViewModels.Customer.ReturnOrderIntentViewModel;
    countries: any;
    addressData: Array<any>;
    customerName: string | null;
    customerEmail: string | null;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.EditFormAutomationsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    formAutomation: App.Models.ViewModels.FormAutomationViewModel;
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    returnStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    requestStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    returnInstructions: Array<App.Models.ViewModels.ReturnInstructionViewModel>;
    selectableTriggerOptions: {
      [key: number]: App.Models.ViewModels.FormAutomationTriggerOptionViewModel;
    };
    actionOptions: { [key: string]: { [key: number]: any } };
    formAutomationPlatformIntegrationOptions: Array<App.Models.ViewModels.ActionOptions.FormAutomationPlatformIntegrationOptionViewModel>;
    nonApplicableConditionFields: { [key: number]: string };
    currentSelectedTriggerOption: App.Models.ViewModels.FormAutomationTriggerOptionViewModel;
    conditionTypeMap: { [key: string]: string };
    availableConditionTypes: { [key: string]: { [key: string]: string } };
    returnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.EditGeneralFormViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    formLocales: Array<App.Models.ViewModels.FormLocaleViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    productDetailTypes: Array<App.Enums.ProductDetailType>;
    orderMutationTypes: Array<App.Enums.FormNotificationType>;
    domain: string | null;
    hasActiveReturnImporters: boolean;
  };
  'App.Models.ViewModels.EmailTemplateViewModel': {
    id: string;
    subject: string;
    body: string;
    locale: App.Models.ViewModels.LocaleViewModel;
  };
  'App.Models.ViewModels.ExternalShipmentViewModel': {
    id: string;
    trackingNumber: string;
    carrierName: string;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FailedFormAutomationViewModel': {
    applicationLogUrl: string;
    id: string;
    formAutomationActionType: string;
    formAutomationName: string;
    returnNumber: string | null;
    returnOrderUrl: string | null;
    failedAt: string;
    retriedAt: string | null;
    message: string;
    meta: Array<any>;
    hasTrashedFormAutomationAction: boolean;
  };
  'App.Http.ViewModels.Panel.FeatureUsageInfoViewModel': {
    featureLabel: string;
    hasPlan: boolean;
    limit: number | null;
    limitLabel: string;
    usage: number;
    hasExceededLimit: boolean;
    hasReachedLimit: boolean;
    isAvailable: boolean;
    suggestedPlan: string;
    warningMessage: string;
  };
  'App.Http.ViewModels.Panel.Analytics.FilterOptionViewModel': {
    label: string;
    value: string;
  };
  'App.Models.ViewModels.FilterTabViewModel': {
    id: string;
    label: string;
    filters: Array<any>;
    queryString: string;
  };
  'App.Models.ViewModels.FormAutomationActionViewModel': {
    type: any;
    value: string | null;
    platform_integration_tenant_id: string | null;
    hasFailed: boolean;
  };
  'App.Models.ViewModels.ActionOptions.FormAutomationPlatformIntegrationOptionViewModel': {
    label: string;
    uuid: string;
    actions: Array<any>;
  };
  'App.Models.ViewModels.FormAutomationTriggerOptionViewModel': {
    label: string;
    value: string;
    parameterTitle: string | null;
    parameters: any | null;
  };
  'App.Models.ViewModels.FormAutomationViewModel': {
    id: string;
    name: string;
    trigger: any;
    parameter: string | null;
    conditions: Array<any>;
    isEnabled: boolean;
    formAutomationActions: {
      [key: string]: App.Models.ViewModels.FormAutomationActionViewModel;
    };
    tags: Array<App.Models.ViewModels.TagViewModel>;
    broken: boolean;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormDomainsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    domains: any;
    locales: any;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormLayoutViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    formLocales: any;
    formLocale: App.Models.ViewModels.FormLocaleViewModel;
    formLocaleFallback: App.Http.ViewModels.Panel.Settings.Forms.FormLocaleFallbackViewModel;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<any>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormLocaleFallbackViewModel': {
    orderFieldLabel: string;
    orderFieldPlaceholder: string;
    validationFieldLabel: string;
    validationFieldPlaceholder: string;
    searchButtonLabel: string;
  };
  'App.Models.ViewModels.FormLocaleViewModel': {
    id: string;
    title: string | null;
    introText: string | null;
    syncWithDefault: boolean;
    isDefault: boolean;
    logoLink: string | null;
    logoSource: string | null;
    coverPhotoLink: string | null;
    coverPhotoSource: string | null;
    replyToAddress: string | null;
    shopName: string | null;
    websiteUrl: string | null;
    brandColor: string | null;
    brandContrastColor: string | null;
    isEnabled: boolean;
    createdAt: string;
    updatedAt: string;
    locale: App.Models.ViewModels.LocaleViewModel;
    domain: App.Models.ViewModels.DomainViewModel | null;
    orderFieldLabel: string | null;
    orderFieldPlaceholder: string | null;
    searchButtonLabel: string | null;
  };
  'App.Models.ViewModels.Customer.FormLocaleViewModel': {
    id: string;
    title: string | null;
    introText: string | null;
    isDefault: boolean;
    replyToAddress: string | null;
    shopName: string | null;
    brandColor: string;
    brandContrastColor: string;
    isEnabled: boolean;
    createdAt: string;
    updatedAt: string;
    locale: App.Models.ViewModels.Customer.LocaleViewModel;
    domain: App.Models.ViewModels.Customer.DomainViewModel | null;
    orderFieldLabel: string;
    orderFieldPlaceholder: string | null;
    searchButtonLabel: string;
  };
  'App.Models.ViewModels.FormPlatformIntegrationTenantViewModel': {
    id: string;
    platformIntegrationTenant: App.Models.ViewModels.PlatformIntegrationTenantViewModel;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormReturnReasonViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    returnReasons: any;
    allReturnReasons: any;
  };
  'App.Models.ViewModels.FormShippingMethodViewModel': {
    id: string;
    form: App.Models.ViewModels.FormViewModel;
    shippingMethod: App.Models.ViewModels.ShippingMethodViewModel;
    price: string | null;
    priceRaw: number;
    priceMajor: string;
    isActive: boolean;
    isPreferred: boolean;
    returnAddress: App.Models.ViewModels.ReturnAddressViewModel;
    shippingInstruction: App.Models.ViewModels.ShippingInstructionViewModel | null;
    shippingContract: App.Models.ViewModels.ShippingContractViewModel | null;
    name: string | null;
  };
  'App.Models.ViewModels.Customer.FormShippingMethodViewModel': {
    id: string;
    shippingMethod: App.Models.ViewModels.Customer.ShippingMethodViewModel;
    price: string | null;
    priceRaw: number;
    returnAddress: App.Models.ViewModels.Customer.ReturnAddressViewModel | null;
    name: string | null;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsFilterViewModel': {
    shippingMethodTypes: Array<App.Enums.ShippingMethodType>;
    fromCountries: Array<App.Models.ViewModels.CountryViewModel>;
    toCountries: Array<App.Models.ViewModels.CountryViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsItemViewModel': {
    id: string;
    name: string;
    carrierImage: string | null;
    fromCountry: string;
    toCountry: string;
    minWeight: string | null;
    maxWeight: string | null;
    cost: string;
    price: string | null;
    type: App.Enums.ShippingMethodType;
    maxWidth: string;
    maxHeight: string;
    maxLength: string;
    productCode: string | null;
    isPrinterless: boolean;
    isSelected: boolean;
    isDeactivated: boolean;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsListViewModel': {
    previousPageUrl: string | null;
    nextPageUrl: string | null;
    paginatorStatus: string;
    shippingMethods: Array<App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsItemViewModel>;
    filters: Array<any>;
    perPage: string | number;
    filterTabs: Array<App.Models.ViewModels.FilterTabViewModel>;
    hasFilterTabs: boolean;
    hasActiveFilter: boolean;
    sort: string | null;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    contractTabs: any;
    customShippingContracts: Array<App.Models.ViewModels.ShippingContractViewModel>;
    allCountries: Array<App.Models.ViewModels.CountryViewModel>;
    returnAddresses: Array<App.Models.ViewModels.ReturnAddressViewModel>;
    selectedContract: App.Models.ViewModels.ShippingContractViewModel | null;
    currentEditingShippingMethod: App.Models.ViewModels.ShippingMethodViewModel | null;
    currentEditingFormShippingMethod: App.Models.ViewModels.FormShippingMethodViewModel | null;
    isEditingShippingMethod: boolean;
    shippingInstructions: Array<App.Models.ViewModels.ShippingInstructionViewModel>;
    hasCustomShippingIntegration: boolean;
    formShippingMethodsListViewModel: App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsListViewModel;
    formShippingMethodsFilterViewModel: App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsFilterViewModel;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    shippingLabelReferenceTypes: Array<App.Enums.ShippingLabelReferenceType>;
    returnInstructions: Array<App.Models.ViewModels.ReturnInstructionViewModel>;
    shippingInstructions: Array<App.Models.ViewModels.ShippingInstructionViewModel>;
    countryOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    returnOrderMetadataKeys: Array<string>;
    barcodeTypeOptions: Array<any>;
    printProductIdentifierField: any;
    printProductBarcodeField: any;
    printProductFieldTypeOptions: Array<any>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationItemViewModel': {
    id: string;
    name: string;
    imageSrc: string | null;
    city: string;
    country: string;
    isSelected: boolean;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationListViewModel': {
    previousPageUrl: string | null;
    nextPageUrl: string | null;
    paginatorStatus: string;
    storeLocations: Array<App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationItemViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    storeLocationList: App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationListViewModel;
    canUseStoreLocations: boolean;
  };
  'App.Models.ViewModels.FormViewModel': {
    id: string;
    name: string;
    description: string | null;
    isEnabled: boolean;
    isInsuranceActive: boolean;
    domainRouting: string;
    defaultFormLocale: App.Models.ViewModels.FormLocaleViewModel;
    shippingMethods: Array<App.Models.ViewModels.ShippingMethodViewModel> | null;
    locales: Array<App.Models.ViewModels.LocaleViewModel> | null;
    visibleProductDetails: Array<App.Enums.ProductDetailType>;
    returnOrderMutationNotificationPreferences: Array<any>;
    faviconUrl: string | null;
    createdAt: string;
    updatedAt: string;
    shouldBlockSearchEngineIndexing: boolean;
    isEmailChangeAllowed: boolean;
    areCustomerNotesAllowed: boolean;
    shippingLabelReferenceType: App.Enums.ShippingLabelReferenceType;
    shippingLabelReferenceTypeKey: string | null;
    multicollo: number | null;
    freeShippingForMulticollo: boolean;
    additionalShipments: number;
    showPriceWhenFreeShipping: boolean;
    defaultProductWeight: number;
    defaultProductLength: number;
    defaultProductWidth: number;
    defaultProductHeight: number;
    dynamicProductWeight: boolean;
    dynamicProductDimensions: boolean;
    defaultHSCode: string | null;
    defaultCountryOfOrigin: App.Models.ViewModels.CountryViewModel | null;
    requiredPhoneNumber: boolean;
    sendShippingNotificationsFromCarriers: boolean;
    isTestModeEnabled: boolean;
    primaryPlatformIntegrationTenant: App.Models.ViewModels.PlatformIntegrationTenantViewModel | null;
    gtmContainerId: string | null;
    isPrintReturnFormEnabled: boolean;
    isRandomReasonsOrder: boolean;
    isVirtual: boolean;
    barcodeType: string;
    storeLocationMaxRadius: number;
    storeLocationsEnabled: boolean;
    storeLocationMaxAmount: number;
    storeLocationShowBarcode: boolean;
    storeLocationShowQrCode: boolean;
    storeLocationShowNearbyLocationsButton: boolean;
    storeLocationShippingMethodLabelsPerLocale: { [key: string]: string };
    storeLocationShippingMethodDescriptionsPerLocale: { [key: string]: string };
    storeLocationShippingInstructionUuid: string | null;
    shippingSettings: App.Models.ViewModels.ShippingSettingsViewModel;
    dynamicReturnCostsPerItem: string;
    dynamicReturnCostsStartFrom: number;
    dynamicReturnCostReturnInstructionId: string | null;
    requiredOrderNumberPrefixes: string | null;
    shouldUseUrlAsLinkForLogo: boolean;
  };
  'App.Models.ViewModels.Customer.FormViewModel': {
    id: string;
    name: string;
    description: string | null;
    isEnabled: boolean;
    defaultFormLocale: App.Models.ViewModels.Customer.FormLocaleViewModel;
    shippingMethods: Array<App.Models.ViewModels.Customer.ShippingMethodViewModel> | null;
    locales: Array<App.Models.ViewModels.Customer.LocaleViewModel> | null;
    visibleProductDetails: Array<App.Enums.ProductDetailType>;
    createdAt: string;
    updatedAt: string;
    isEmailChangeAllowed: boolean;
    areCustomerNotesAllowed: boolean;
    multicollo: number | null;
    additionalShipments: number;
  };
  'App.Http.ViewModels.Panel.Analytics.HorizontalBarChartItemViewModel': {
    id: string;
    label: string;
    value: number;
    color: string;
    percentage: number;
  };
  'App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel': {
    data: {
      [
      key: number
      ]: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartItemViewModel;
    };
  };
  'App.Models.ViewModels.Customer.InsurancePackageViewModel': {
    id: string;
    insuredValue: string;
    insuredValueRaw: number;
    internationalPrice: string;
    internationalPriceRaw: number;
    domesticPrice: string;
    domesticPriceRaw: number;
  };
  'App.Models.ViewModels.InvitationViewModel': {
    id: string;
    resourceType: string;
    email: string;
    profilePhotoUrl: string;
    statusBadge: { color: string; label: string; icon: string };
  };
  'App.Models.ViewModels.InvoiceViewModel': {
    id: string;
    date: string;
    invoiceNumber: string | null;
    amount: string;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ListApiKeysViewModel': {
    tokens: Array<App.Models.ViewModels.PersonalAccessTokenViewModel>;
    userTokenIds: Array<string>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ListFailedFormAutomationsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    previousPageUrl: string | null;
    nextPageUrl: string | null;
    perPage: number;
    total: number;
    failedFormAutomations: Array<App.Http.ViewModels.Panel.Settings.Forms.FailedFormAutomationViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ListFormAutomationsViewModel': {
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    form: App.Models.ViewModels.FormViewModel;
    formAutomations: Array<App.Models.ViewModels.FormAutomationViewModel>;
    hasFailedFormAutomation: boolean;
  };
  'App.Http.ViewModels.Customer.ListPaymentMethodsViewModel': {
    returnOrderIntent: App.Models.ViewModels.Customer.ReturnOrderIntentViewModel;
    price: string;
    paymentMethods: Array<App.Models.ViewModels.Customer.PaymentMethodViewModel>;
    selectedPaymentMethod: App.Models.ViewModels.Customer.PaymentMethodViewModel | null;
    paymentStatus: string | null;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.ListReturnQuestionsViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    returnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.ListReturnRulesViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    returnRules: Array<App.Models.ViewModels.ReturnRuleViewModel>;
    itemRules: Array<App.Models.ViewModels.ReturnRuleViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.ListShippingContractsViewModel': {
    shippingContracts: Array<App.Models.ViewModels.ShippingContractViewModel>;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.ListShippingIntegrationViewModel': {
    shippingIntegrations: Array<App.Models.ViewModels.ShippingIntegrationViewModel>;
  };
  'App.Http.ViewModels.Customer.ListShippingMethodsViewModel': {
    returnOrderIntentId: string;
    alreadyFinalized: boolean;
    shippingMethodOptions: Array<App.Http.ViewModels.Customer.ShippingMethodOptionViewModel>;
    selectedFormShippingMethodId: string | null;
    insurancePackages: Array<App.Models.ViewModels.Customer.InsurancePackageViewModel>;
    selectedInsurancePackageId: string | null;
    fromCountry: App.Models.ViewModels.CountryViewModel | null;
    returnInStoreShippingMethod: App.Models.ViewModels.ReturnInStoreShippingMethodViewModel | null;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ListWebhooksViewModel': {
    webhookSubscriptions: Array<App.Models.ViewModels.WebhookSubscriptionViewModel>;
    events: Array<string>;
    signingSecret: string;
    testMode: boolean;
  };
  'App.Models.ViewModels.LocaleViewModel': {
    id: string;
    locale: string;
    name: string;
    countryCode: string;
    isoCode: string;
    createdAt: string;
    updatedAt: string;
  };
  'App.Models.ViewModels.Customer.LocaleViewModel': {
    id: string;
    locale: string;
    name: string;
    countryCode: string;
    createdAt: string;
    updatedAt: string;
  };
  'App.Http.ViewModels.Panel.Settings.Templates.ShippingInstructions.Edit.LocaleViewModel': {
    id: string;
    locale: string;
    name: string;
    countryCode: string;
    isoCode: string;
    createdAt: string;
    updatedAt: string;
  };
  'App.Models.ViewModels.MemberViewModel': {
    id: string;
    resourceType: string;
    email: string;
    name: string;
    profilePhotoUrl: string;
    statusBadge: { color: string; label: string; icon: string };
    twoFactorStatusBadge: { color: string; label: string; icon: string };
  };
  'App.Models.ViewModels.NoteTemplateLocaleViewModel': {
    id: string;
    content: string;
    locale: App.Models.ViewModels.LocaleViewModel;
  };
  'App.Models.ViewModels.NoteTemplateViewModel': {
    id: string;
    title: string;
    description: string | null;
    isEnabledOnRequests: boolean;
    isEnabledOnReturns: boolean;
    isEnabledOnPaymentRequests: boolean;
    noteTemplateLocales: {
      [key: number]: App.Models.ViewModels.NoteTemplateLocaleViewModel;
    };
  };
  'App.Models.ViewModels.NoteViewModel': {
    id: string;
    note: string;
    actor: string | null;
    attachments: any;
    isVisibleForCustomer: boolean;
    user: App.Models.ViewModels.UserViewModel | null;
    customer: App.Models.ViewModels.CustomerViewModel | null;
    createdAt: string;
  };
  'App.Models.ViewModels.Customer.NoteViewModel': {
    id: string;
    note: string;
    attachments: any;
    isVisibleForCustomer: boolean;
    user: App.Models.ViewModels.UserViewModel | null;
    customer: App.Models.ViewModels.CustomerViewModel | null;
    createdAt: string;
  };
  'App.Http.ViewModels.Panel.Account.NotificationViewModel': {
    group: string;
    title: string;
    description: string;
    notification: string;
    notifications: Array<string>;
  };
  'App.Http.ViewModels.Panel.OnboardingDashboardViewModel': {
    tenant: App.Models.ViewModels.TenantViewModel;
    countries: any;
    formLocaleFallback: App.Http.ViewModels.Panel.Settings.Forms.FormLocaleFallbackViewModel | null;
    onboardingStatus: {
      hasReturnAddress: boolean;
      hasReturnForm: boolean;
      hasReturnQuestions: boolean;
      hasReturnRules: boolean;
      hasTestReturn: boolean;
    };
    returnAddress: App.Models.ViewModels.ReturnAddressViewModel | null;
    platformIntegrations: any;
    returnForm: App.Models.ViewModels.FormViewModel | null;
    formLocale: App.Models.ViewModels.FormLocaleViewModel | null;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<any>;
    onboardingIndustries: any;
    predefinedReturnQuestions: Array<App.Models.ViewModels.PredefinedReturnQuestionViewModel>;
    storedReturnQuestions: Array<App.Models.ViewModels.ReturnQuestionViewModel>;
    predefinedReturnRules: Array<App.Models.ViewModels.PredefinedReturnRuleViewModel>;
    storedReturnRules: Array<App.Models.ViewModels.ReturnRuleViewModel>;
    usedReturnQuestions: Array<App.Models.ViewModels.ReturnQuestionViewModel>;
  };
  'App.Models.ViewModels.Analytics.OverviewProductViewModel': {
    id: string;
    name: string | null;
    imageSource: string | null;
    sku: string | null;
    amountReturnOrders: number;
    quantityReturned: number;
    value: string;
  };
  'App.Http.ViewModels.Panel.Analytics.Overview.OverviewViewModel': {
    overviewReturnsDataPoints: Array<App.Http.ViewModels.Panel.Analytics.DataPoint>;
    shipmentNumbers: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentRealtimeNumbersViewModel;
    periodicalShipmentNumbersBarChart: App.Http.ViewModels.Panel.Analytics.BarChartViewModel;
    deliveryPredictions: Array<App.Http.ViewModels.Panel.Analytics.PredictionViewModel>;
    filters: Array<any>;
    filterTabs: Array<App.Models.ViewModels.FilterTabViewModel>;
    hasFilterTabs: boolean;
    hasActiveFilter: boolean;
    forms: Array<App.Models.ViewModels.FormViewModel>;
    states: any;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    returnStatuses: Array<App.Models.ViewModels.ReturnStatusViewModel>;
    requestStatuses: Array<App.Models.ViewModels.RequestStatusViewModel>;
    returnReasons: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel;
    dateFilter: App.Models.ViewModels.Analytics.DateAnalyticsFilterViewModel;
  };
  'App.Models.ViewModels.OwnershipTransferRequestViewModel': {
    id: string;
    originalOwner: string;
    newOwner: string;
    createdAt: string;
  };
  'App.Models.ViewModels.PartnerAccessInvitationViewModel': {
    id: string;
    partner: App.Models.ViewModels.TenantViewModel;
    tenant: App.Models.ViewModels.TenantViewModel;
  };
  'App.Http.ViewModels.Panel.Account.PartnerManagementViewModel': {
    user: App.Models.ViewModels.UserViewModel;
    tenant: App.Models.ViewModels.TenantViewModel;
    partners: Array<App.Http.ViewModels.Panel.Account.PartnerManagement.PartnerViewModel>;
    tenants: Array<App.Http.ViewModels.Panel.Account.PartnerManagement.TenantViewModel>;
    hasAccess: boolean;
    invitations: any | null;
    partnerVerificationCode: string | null;
    createAccountUrl: string | null;
  };
  'App.Http.ViewModels.Panel.Account.PartnerManagement.PartnerViewModel': {
    id: string;
    name: string;
  };
  'App.Models.ViewModels.PaymentMethodViewModel': {
    id: string;
    name: string;
    systemName: string;
  };
  'App.Models.ViewModels.Customer.PaymentMethodViewModel': {
    id: string;
    name: string;
    systemName: string;
  };
  'App.Models.ViewModels.PaymentRequestLineViewModel': {
    id: string;
    sortOrder: number;
    description: string;
    quantity: number;
    price: number;
    required: boolean;
  };
  'App.Http.ViewModels.Customer.PaymentRequestLineViewModel': {
    id: string;
    description: string;
    quantity: number;
    price: number;
    required: boolean;
  };
  'App.Models.ViewModels.PaymentRequestViewModel': {
    id: string;
    description: string;
    referenceNumber: string;
    paymentRequestLines: Array<App.Models.ViewModels.PaymentRequestLineViewModel>;
    total: string;
    totalSelected: string;
    isPaid: boolean;
    hasStartedPaying: boolean;
    selectedPaymentRequestLineIds: Array<any>;
  };
  'App.Models.ViewModels.PaymentTransactionViewModel': {
    id: string;
    paymentMethod: App.Models.ViewModels.PaymentMethodViewModel;
    currency: string;
    amount: string;
    amountCaptured: string;
    isEuroPayment: boolean;
    costPrice: string;
    status: App.Enums.PaymentStatusType | null;
    refundStatus: App.Enums.RefundStatusType | null;
    transactionType: App.Enums.PaymentTransactionType;
    isRefunded: boolean;
    error: App.Enums.PaymentTransactionErrorType | null;
  };
  'App.Models.ViewModels.Customer.PaymentTransactionViewModel': {
    id: string;
    paymentMethod: App.Models.ViewModels.Customer.PaymentMethodViewModel;
    currency: string;
    amount: string;
    status: App.Enums.PaymentStatusType | null;
  };
  'App.Models.ViewModels.PermissionViewModel': {
    permissionGroup: any;
    systemName: App.Enums.PermissionType;
    enabled: boolean;
  };
  'App.Models.ViewModels.PersonalAccessTokenViewModel': {
    id: string;
    name: string;
    lastUsedAt: string | null;
  };
  'App.Models.ViewModels.PlatformIntegrationTenantViewModel': {
    id: string;
    tenant: App.Models.ViewModels.TenantViewModel;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel;
    title: string | null;
    description: string | null;
  };
  'App.Models.ViewModels.PlatformIntegrationViewModel': {
    id: string;
    driver: string;
    name: string;
    logo: { name: string; imageSourceUrl: string };
    documentationUrl: string;
    isBeta: boolean;
    canCreateCoupon: boolean;
    canCreateRefund: boolean;
    canCreateReturn: boolean;
    canSearchOrder: boolean;
    canCreateGiftCard: boolean;
    canFinalizeReturn: boolean;
    requiresSubscription: boolean;
  };
  'App.Models.ViewModels.PlatformWebhookViewModel': {
    externalId: string | null;
    name: string;
    event: string;
    address: string;
    status: string;
  };
  'App.Models.ViewModels.PredefinedReturnQuestionViewModel': {
    question: string | null;
    description: string | null;
    fieldType: string | null;
    level: string | null;
    isPopular: boolean;
    isShown: boolean;
  };
  'App.Models.ViewModels.PredefinedReturnRuleViewModel': {
    name: string | null;
    isPopular: boolean;
    isShown: boolean;
  };
  'App.Http.ViewModels.Panel.Analytics.PredictionViewModel': {
    label: string;
    range: string;
    minimum: number;
    maximum: number;
  };
  'App.Http.ViewModels.Panel.Account.PreferencesViewModel': {
    notifications: {
      [key: string]: {
        [key: string]: App.Http.ViewModels.Panel.Account.NotificationViewModel;
      };
    };
    preferences: App.Models.ViewModels.UserPreferenceViewModel;
    platformIntegrationsForBarcodeRedirect: { [key: string]: string };
    user: App.Models.ViewModels.UserViewModel;
  };
  'App.Models.ViewModels.Customer.PreviousIntentViewModel': {
    id: string;
    returnNumber: string | null;
    previewUrl: string | null;
    isFinalized: boolean;
    createdAt: string | null;
  };
  'App.Http.ViewModels.Panel.Analytics.Products.ProductAnalyticsViewModel': {
    returnReasons: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel;
    filter: App.Models.ViewModels.Analytics.DateAnalyticsFilterViewModel;
    product: App.Models.ViewModels.ProductViewModel;
    returnOrderItemsInformation: App.Models.ViewModels.Analytics.ReturnOrderItemsInformationViewModel;
    returnOrderItemsQuantityInformation: App.Models.ViewModels.Analytics.ReturnOrderItemsQuantityInformationViewModel;
    amountOfReturn: Array<App.Http.ViewModels.Panel.Analytics.DataPoint>;
    lastReturns: Array<App.Http.ViewModels.Panel.Analytics.Products.ReturnViewModel>;
    formsQuestionsAnswers: Array<App.Models.ViewModels.Analytics.AnalyticsFormQuestionsAnswersViewModel>;
  };
  'App.Models.ViewModels.ProductViewModel': {
    id: string;
    externalId: string;
    sku: string | null;
    barcode: string | null;
    name: string | null;
    width: number | null;
    length: number | null;
    height: number | null;
    hsCode: string | null;
    brand: string | null;
    categories: Array<App.Models.ViewModels.CategoryViewModel>;
    supplier: string | null;
    imageSource: string | null;
    url: string | null;
  };
  'App.Http.ViewModels.Customer.ProductViewModel': {
    id: string;
    externalId: string;
    productType: string | null;
    parent: App.Http.ViewModels.Customer.ProductViewModel | null;
    hasChildren: boolean;
    sku: string | null;
    barcode: string | null;
    name: string | null;
    width: number | null;
    length: number | null;
    height: number | null;
    hsCode: string | null;
    brand: string | null;
    categories: Array<App.Models.ViewModels.CategoryViewModel>;
    supplier: string | null;
    imageSource: string | null;
    url: string | null;
  };
  'App.Http.ViewModels.Panel.Analytics.Products.ProductsDataTableViewModel': {
    previousPageUrl: string | null;
    nextPageUrl: string | null;
    paginatorStatus: string;
    products: Array<App.Models.ViewModels.Analytics.OverviewProductViewModel>;
    filters: Array<any>;
    perPage: number;
    forms: Array<App.Models.ViewModels.FormViewModel>;
    filterTabs: Array<App.Models.ViewModels.FilterTabViewModel>;
    hasFilterTabs: boolean;
    hasActiveFilter: boolean;
    brands: Array<string>;
    suppliers: Array<string>;
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    dateFilter: App.Models.ViewModels.Analytics.DateAnalyticsFilterViewModel;
    sort: string | null;
  };
  'App.Http.ViewModels.Panel.Analytics.Products.ProductsViewModel': {
    productsDataTable: App.Http.ViewModels.Panel.Analytics.Products.ProductsDataTableViewModel;
  };
  'App.Http.ViewModels.Panel.Account.ProfileViewModel': {
    user: App.Models.ViewModels.UserViewModel;
    tenant: App.Models.ViewModels.TenantViewModel;
    isOwner: boolean;
    partners: Array<App.Models.ViewModels.TenantViewModel>;
    languages: Array<App.Models.ViewModels.TranslationLanguageViewModel>;
  };
  'App.Http.ViewModels.Panel.Analytics.RangeChartItemViewModel': {
    label: string;
    lowValue: number;
    highValue: number;
    color: string;
    value: number | null;
  };
  'App.Http.ViewModels.Panel.Analytics.RangeChartViewModel': {
    dataset: Array<App.Http.ViewModels.Panel.Analytics.RangeChartItemViewModel>;
    from: number;
    to: number;
  };
  'App.Http.ViewModels.Panel.Analytics.RankingItemViewModel': {
    label: string;
    rank: number;
    value: number;
    percentage: number;
    image: string | null;
    item: any;
  };
  'App.Models.ViewModels.RefundItemViewModel': {
    id: string;
    name: string | null;
    quantity: number;
  };
  'App.Models.ViewModels.RefundViewModel': {
    id: string;
    amount: string;
    externalId: string | null;
    refundItems: Array<App.Models.ViewModels.RefundItemViewModel>;
    createdAt: string | null;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel;
  };
  'App.Http.ViewModels.Panel.Requests.Detail.RequestOrderItemViewModel': {
    id: string;
    product:
      | App.Models.ViewModels.ProductViewModel
      | App.Models.ViewModels.CombinedProductViewModel;
    returnReason: App.Models.ViewModels.ReturnReasonViewModel;
    salesOrderItem: App.Models.ViewModels.SalesOrderItemViewModel | any;
    notes: any;
    weight: number;
    quantity: number;
    quantityReceived: number;
    quantityRefunded: number;
    quantityBackInStock: number;
    quantityReturnedInStock: number;
    depreciations: any;
    originalValue: string;
    rawTotalDepreciation: number;
    totalDepreciation: any;
    remainingValue: any;
    returnType: string | null;
    answers: Array<App.Http.ViewModels.Panel.Requests.Detail.RequestQuestionAnswerViewModel>;
  };
  'App.Http.ViewModels.Panel.Requests.RequestOrderOverviewItemViewModel': {
    id: string;
    returnNumber: string;
    currency: string;
    customerName: string;
    customerEmail: string | null;
    status: { color: string; label: string; icon: string };
    state: string;
    value: string;
    createdAt: string;
    salesOrderNumber: string;
    quantity: number;
    hasUnreadNotes: boolean;
    noteCount: number;
    tags: Array<App.Models.ViewModels.TagViewModel>;
  };
  'App.Models.ViewModels.RequestOrderViewModel': {
    id: string;
    returnNumber: string;
    currency: string;
    discountTotal: number;
    discountVat: number;
    shippingTotal: number;
    shippingVat: number;
    total: number;
    totalVat: number;
    status: { color: string; label: string; icon: string };
    state: string;
    value: string;
    quantity: number;
    freeShipping: boolean;
    shippingIntegrations: any;
    shipments: Array<App.Models.ViewModels.ShipmentViewModel>;
    customer: App.Models.ViewModels.CustomerViewModel;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    notes: any;
    hasUnreadNotes: boolean;
    createdAt: string;
    answers: any;
    previewUrl: string | null;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel | null;
    salesOrders: Array<App.Models.ViewModels.SalesOrderViewModel>;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    isTestRecord: boolean;
    locale: App.Models.ViewModels.LocaleViewModel;
  };
  'App.Http.ViewModels.Panel.Requests.Detail.RequestOrderViewModel': {
    id: string;
    returnNumber: string;
    currency: string;
    discountTotal: number;
    discountVat: number;
    shippingTotal: number;
    shippingVat: number;
    total: number;
    totalVat: number;
    status: { color: string; label: string; icon: string };
    state: string;
    value: string;
    quantity: number;
    freeShipping: boolean;
    shippingIntegrations: any;
    shipments: Array<App.Models.ViewModels.ShipmentViewModel>;
    customer: App.Models.ViewModels.CustomerViewModel;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    notes: any;
    hasUnreadNotes: boolean;
    createdAt: string;
    answers: any;
    previewUrl: string | null;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel | null;
    salesOrders: Array<App.Models.ViewModels.SalesOrderViewModel>;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    isTestRecord: boolean;
    locale: App.Models.ViewModels.LocaleViewModel;
    conflictMessage: string | null;
  };
  'App.Http.ViewModels.Panel.Requests.RequestOrdersOverviewViewModel': {
    previousPageUrl: string | null;
    nextPageUrl: string | null;
    paginatorStatus: string;
    orders: any;
    filters: {
      created_at: Record<string, string>;
      quantity: Record<string, string>;
      value: Record<string, string>;
      platform_integration: string | null;
      form: string | null;
      return_status: string | null;
      tenant: string | null;
      return_type: string | null;
      shipping_status: string | null;
      note_filter: string | null;
      locale: string | null;
      is_test_record: string | null;
    };
    perPage: number;
    forms: any;
    locales: any;
    platformIntegrations: any;
    filterTabs: Array<App.Models.ViewModels.FilterTabViewModel>;
    hasFilterTabs: boolean;
    hasActiveFilter: boolean;
    requestStatuses: any;
    returnOrderStates: Array<any>;
    noteFilterOptions: Array<any>;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    sort: string | null;
  };
  'App.Http.ViewModels.Panel.Requests.Detail.RequestQuestionAnswerViewModel': {
    id: string;
    question: string;
    answer: string | null;
    attachments: Array<App.Models.ViewModels.AttachmentViewModel> | null;
  };
  'App.Models.ViewModels.RequestStatusViewModel': {
    id: string;
    label: string;
    color: string;
    isDefaultStatus: boolean;
    visibleOnTrackingPage: boolean;
    shouldNotifyUser: boolean;
    statusBadge: { color: string; label: string; icon: string };
    labelTranslations: Array<any>;
    completed: boolean;
  };
  'App.Models.ViewModels.Customer.RequestStatusViewModel': {
    id: string;
    label: string;
    color: string;
    statusBadge: { color: string; label: string; icon: string };
    completed: boolean;
  };
  'App.Models.ViewModels.ReturnAddressViewModel': {
    id: string;
    addressType: App.Enums.ReturnAddressType;
    imageSrc: string | null;
    rawImageSrc: string | null;
    rawMapImageSrc: string | null;
    name: string;
    companyName: string | null;
    telephone: string;
    street: string;
    houseNumber: string;
    suffix: string | null;
    postalCode: string;
    city: string;
    state: string | null;
    latitude: string | null;
    longitude: string | null;
    primary: boolean;
    isConnectedToShippingMethods: boolean;
    createdAt: string;
    updatedAt: string;
    completeAddress: string;
    addressLine: string;
    country: App.Models.ViewModels.CountryViewModel;
    googleMapsPlacesId: string | null;
  };
  'App.Models.ViewModels.Customer.ReturnAddressViewModel': {
    id: string;
    name: string;
    telephone: string;
    street: string;
    houseNumber: string;
    suffix: string | null;
    postalCode: string;
    city: string;
    completeAddress: string;
    createdAt: string;
    updatedAt: string;
    country: App.Models.ViewModels.Customer.CountryViewModel;
  };
  'App.Http.ViewModels.Panel.Settings.ReturnAddresses.ReturnAddressesViewModel': {
    featureUsageInfo: App.Http.ViewModels.Panel.FeatureUsageInfoViewModel;
    returnAddresses: Array<App.Models.ViewModels.ReturnAddressViewModel>;
    countries: any;
    returnAddress: App.Models.ViewModels.ReturnAddressViewModel | null;
    addressType: App.Enums.ReturnAddressType;
  };
  'App.Models.ViewModels.Customer.ReturnFormQuestionViewModel': {
    isEnabled: boolean;
    isAnswerRequired: boolean;
    id: string;
    level: string;
    fieldType: string;
    answers: any | null;
    question: string;
    description: string | null;
    conditions: Array<any> | null;
  };
  'App.Models.ViewModels.ReturnFormQuestionViewModel': {
    isEnabled: boolean;
    isAnswerRequired: boolean;
    id: string;
    level: string;
    fieldType: string;
    answers: any | null;
    question: string;
    description: string | null;
    conditions: Array<any> | null;
  };
  'App.Models.ViewModels.ReturnInStoreShippingMethodViewModel': {
    label: string;
    description: string;
    showNearbyStoreLocationsButton: boolean;
    nearbyStoreLocations: Array<App.Http.ViewModels.Customer.StoreLocationViewModel>;
    id: string;
  };
  'App.Models.ViewModels.ReturnInstructionLocaleViewModel': {
    title: string | null;
    content: string | null;
    renderedContent: string | null;
    locale: App.Models.ViewModels.LocaleViewModel;
  };
  'App.Models.ViewModels.Customer.ReturnInstructionLocaleViewModel': {
    id: string | null;
    title: string | null;
    content: string | null;
    renderedContent: string | null;
    locale: App.Models.ViewModels.Customer.LocaleViewModel;
  };
  'App.Models.ViewModels.ReturnInstructionViewModel': {
    id: string;
    name: string;
    returnInstructionLocales: Array<App.Models.ViewModels.ReturnInstructionLocaleViewModel>;
    isDefault: boolean;
  };
  'App.Models.ViewModels.ReturnOrderEventViewModel': {
    id: string;
    content: string;
    actorName: string | null;
    actorUrl: string | null;
    date: string;
    shortDate: string;
    icon: Array<any>;
  };
  'App.Models.ViewModels.ReturnOrderIntentViewModel': {
    id: string;
    salesOrderId: string | null;
    returnOrderId: string | null;
    requestData: Array<any>;
    form: App.Models.ViewModels.FormViewModel;
    status: App.Enums.ReturnOrderIntentStatus | null;
    createdAt: string;
    formShippingMethod: App.Models.ViewModels.FormShippingMethodViewModel | null;
    paymentTransactions: Array<App.Models.ViewModels.PaymentTransactionViewModel>;
  };
  'App.Models.ViewModels.Customer.ReturnOrderIntentViewModel': {
    id: string;
    salesOrderId: string | null;
    returnOrderId: string | null;
    requestData: Array<any>;
    form: App.Models.ViewModels.Customer.FormViewModel;
    createdAt: string;
    formShippingMethod: App.Models.ViewModels.Customer.FormShippingMethodViewModel | null;
    paymentTransactions: Array<App.Models.ViewModels.Customer.PaymentTransactionViewModel>;
  };
  'App.Models.ViewModels.ReturnOrderItemDepreciationViewModel': {
    id: string;
    type: string;
    value: number;
    comment: string | null;
    product: App.Models.ViewModels.ProductViewModel;
    formattedValue: string;
    depreciationPercentage: number;
  };
  'App.Models.ViewModels.ReturnOrderItemViewModel': {
    id: string;
    product:
      | App.Models.ViewModels.ProductViewModel
      | App.Models.ViewModels.CombinedProductViewModel;
    returnReason: App.Models.ViewModels.ReturnReasonViewModel;
    salesOrderItem: App.Models.ViewModels.SalesOrderItemViewModel | any;
    notes: any;
    weight: number;
    quantity: number;
    quantityReceived: number;
    quantityRefunded: number;
    quantityBackInStock: number;
    quantityReturnedInStock: number;
    depreciations: any;
    originalValue: string;
    rawTotalDepreciation: number;
    totalDepreciation: any;
    remainingValue: any;
    returnType: string | null;
    answers: any;
  };
  'App.Models.ViewModels.Customer.ReturnOrderItemViewModel': {
    id: string;
    product:
      | App.Models.ViewModels.ProductViewModel
      | App.Models.ViewModels.CombinedProductViewModel;
    returnReason: App.Models.ViewModels.ReturnReasonViewModel;
    salesOrderItem: App.Models.ViewModels.SalesOrderItemViewModel | any;
    weight: number;
    quantity: number;
    answers: Array<App.Models.ViewModels.ReturnQuestionAnswerViewModel>;
    currency: string;
    notes: Array<App.Models.ViewModels.NoteViewModel>;
  };
  'App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderItemViewModel': {
    id: string;
    product:
      | App.Models.ViewModels.ProductViewModel
      | App.Models.ViewModels.CombinedProductViewModel;
    returnReason: string;
    salesOrderItem: App.Models.ViewModels.SalesOrderItemViewModel | any;
    notes: any;
    weight: number;
    quantity: number;
    quantityReceived: number;
    quantityRefunded: number;
    quantityBackInStock: number;
    quantityReturnedInStock: number;
    depreciations: any;
    originalValue: string;
    rawTotalDepreciation: number;
    totalDepreciation: any;
    remainingValue: any;
    returnType: string | null;
    answers: Array<App.Http.ViewModels.Panel.Returns.Detail.ReturnQuestionAnswerViewModel>;
  };
  'App.Models.ViewModels.Analytics.ReturnOrderItemsInformationViewModel': {
    totalSoldValue: string;
    totalItemCostValue: string;
    missedRevenue: string;
  };
  'App.Models.ViewModels.Analytics.ReturnOrderItemsQuantityInformationViewModel': {
    quantity: number;
    quantityReceived: number;
    quantityBackInStock: number;
  };
  'App.Http.ViewModels.Panel.Returns.ReturnOrderOverviewItemViewModel': {
    id: string;
    tenantName: string;
    returnNumber: string;
    customerName: string;
    customerEmail: string | null;
    shipmentMethodImages: { name: string; imageSourceUrl: string | null };
    shipments: Array<App.Http.ViewModels.Panel.Returns.Overview.ReturnOrderOverviewShipmentViewModel>;
    status: { color: string; label: string; icon: string };
    statusViewModel: App.Models.ViewModels.ReturnStatusViewModel;
    hasUnreadNotes: boolean;
    noteCount: number;
    value: any;
    createdAt: string;
    salesOrderNumber: string;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    quantity: number;
  };
  'App.Http.ViewModels.Panel.Returns.Overview.ReturnOrderOverviewShipmentViewModel': {
    trackingNumber: string | null;
    statusBadge: { color: string; label: string; icon: string };
  };
  'App.Models.ViewModels.ReturnOrderViewModel': {
    id: string;
    returnNumber: string;
    orderDate: string;
    currency: string;
    discountTotal: any;
    discountVat: any;
    shippingTotal: any;
    shippingVat: any;
    total: any;
    totalVat: any;
    state: string;
    status: { color: string; label: string; icon: string };
    value: any;
    quantity: number;
    shipments: Array<App.Models.ViewModels.ShipmentViewModel>;
    customer: App.Models.ViewModels.CustomerViewModel;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    notes: any;
    hasUnreadNotes: boolean;
    createdAt: string;
    answers: any;
    panelUrl: string;
    tenantName: string;
    salesOrders: Array<App.Models.ViewModels.SalesOrderViewModel>;
    salesOrder: App.Models.ViewModels.SalesOrderViewModel | null;
    previewUrl: string | null;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel | null;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    recommendedRefundDiscountAmount: number;
    isTestRecord: boolean;
    locale: App.Models.ViewModels.LocaleViewModel;
    form: any;
    useNewReturnOrderStates: boolean;
  };
  'App.Models.ViewModels.Api.ReturnOrderViewModel': {
    id: string;
    returnNumber: string;
    currency: string;
    discountTotal: any;
    discountVat: any;
    shippingTotal: any;
    shippingVat: any;
    total: any;
    totalVat: any;
    state: string;
    status: { color: string; label: string; icon: string };
    value: any;
    quantity: number;
    previewUrl: string | null;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel | null;
    tags: Array<App.Models.ViewModels.TagViewModel>;
  };
  'App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderViewModel': {
    id: string;
    returnNumber: string;
    orderDate: string;
    currency: string;
    shippingTotal: any;
    shippingVat: any;
    total: any;
    state: string;
    status: { color: string; label: string; icon: string };
    value: any;
    quantity: number;
    shipments: { [key: number]: App.Models.ViewModels.ShipmentViewModel };
    externalShipments: {
      [key: number]: App.Models.ViewModels.ExternalShipmentViewModel;
    };
    customer: App.Models.ViewModels.CustomerViewModel;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    notes: any;
    createdAt: string;
    answers: any;
    locale: App.Models.ViewModels.LocaleViewModel;
    salesOrders: Array<App.Models.ViewModels.SalesOrderViewModel>;
    salesOrder: App.Models.ViewModels.SalesOrderViewModel | null;
    previewUrl: string | null;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel | null;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    recommendedRefundDiscountAmount: number;
    returnOrderType: App.Enums.ReturnOrderType | null;
    isTestRecord: boolean;
    useNewReturnOrderStates: boolean;
  };
  'App.Models.ViewModels.ReturnQuestionAnswerViewModel': {
    id: string;
    question: string;
    answer: string | null;
    answerIsUrl: boolean;
    option: any | null;
    attachments: Array<App.Models.ViewModels.AttachmentViewModel> | null;
  };
  'App.Models.ViewModels.Customer.ReturnQuestionAnswerViewModel': {
    id: string;
    question: string;
    answer: string | null;
    option: any | null;
    attachments: Array<App.Models.ViewModels.Customer.AttachmentViewModel> | null;
  };
  'App.Http.ViewModels.Panel.Returns.Detail.ReturnQuestionAnswerViewModel': {
    id: string;
    question: string;
    answer: string | null;
    attachments: Array<App.Models.ViewModels.AttachmentViewModel> | null;
  };
  'App.Models.ViewModels.ReturnQuestionViewModel': {
    id: string;
    isEnabled: boolean;
    isAnswerRequired: boolean;
    level: string;
    fieldType: string;
    question: string;
    conditions: Array<any>;
    translations: Array<any>;
    descriptionTranslations: Array<any>;
    answers: any;
    tags: Array<App.Models.ViewModels.TagViewModel>;
  };
  'App.Models.ViewModels.ReturnReasonViewModel': {
    id: string;
    systemName: string;
    label: string;
  };
  'App.Models.ViewModels.Customer.ReturnReasonViewModel': {
    id: string;
    systemName: string;
    label: string;
  };
  'App.Models.ViewModels.Analytics.ReturnReasonViewModel': {
    percentage: number;
    id: string;
    name: string;
    amount: number;
    color: string;
  };
  'App.Models.ViewModels.ReturnRuleActionViewModel': {
    id: string;
    type: App.Enums.ReturnRuleActionType;
    value: string | null;
  };
  'App.Models.ViewModels.ReturnRuleViewModel': {
    id: string;
    name: string;
    conditions: Array<any>;
    isEnabled: boolean;
    level: App.Enums.EntityLevel;
    trigger: App.Enums.ReturnRuleTrigger;
    returnInstruction: App.Models.ViewModels.ReturnInstructionViewModel | null;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    actions: Array<App.Models.ViewModels.ReturnRuleActionViewModel>;
    broken: boolean;
  };
  'App.Models.ViewModels.ReturnStatusViewModel': {
    id: string;
    label: string;
    color: string;
    isDefaultStatus: boolean;
    visibleOnTrackingPage: boolean;
    shouldNotifyUser: boolean;
    shouldResolveReturnOrder: boolean;
    statusBadge: { color: string; label: string; icon: string };
    labelTranslations: Array<any>;
    completed: boolean;
  };
  'App.Models.ViewModels.Customer.ReturnStatusViewModel': {
    id: string;
    label: string;
    color: string;
    completed: boolean;
  };
  'App.Http.ViewModels.Panel.Analytics.Products.ReturnViewModel': {
    returnNumber: string;
    id: string;
  };
  'App.Models.ViewModels.SalesChannelViewModel': { id: string; name: string };
  'App.Models.ViewModels.SalesOrderItemViewModel': {
    id: string;
    productName: string | null;
    product: App.Models.ViewModels.ProductViewModel;
    externalId: string;
    extensionAttributes: Array<any>;
    quantity: number;
    discount: any;
    price: any;
    rowDiscount: any;
    rowTotalPrice: any;
    itemCost: any;
    vatCode: string | null;
    vatRate: number;
    compatibleSalesOrderItemId: string | null;
  };
  'App.Http.ViewModels.Customer.SalesOrderItemViewModel': {
    id: string;
    product: App.Http.ViewModels.Customer.ProductViewModel;
    productName: string | null;
    externalId: string;
    extensionAttributes: Array<any>;
    quantity: number;
    currency: string;
    discount: any;
    price: any;
    rowDiscount: string;
    returnedQuantity: number;
    returnableQuantity: number;
    rowTotalPrice: string;
    itemCost: any;
    vatCode: string | null;
    vatRate: number;
    hasReturnOrderItems: boolean;
    isReturnable: boolean;
    returnRuleReturnInstruction: App.Models.ViewModels.Customer.ReturnInstructionLocaleViewModel | null;
    metaData: { [key: string]: any };
    isHiddenOnForm: boolean;
    returnAddressIds: Array<string>;
  };
  'App.Models.ViewModels.SalesOrderViewModel': {
    id: string;
    externalId: string | null;
    platformIntegration: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    platformIntegrationTenant: App.Models.ViewModels.PlatformIntegrationTenantViewModel | null;
    customer: App.Models.ViewModels.CustomerViewModel;
    status: string | null;
    paymentMethod: string | null;
    shippingMethod: string | null;
    orderNumber: string;
    orderDate: string;
    currency: string;
    discountCode: string | null;
    discountDescription: string | null;
    carrier: string | null;
    trackingNumber: string | null;
    deliveryDate: string | null;
    salesOrderItems: Array<App.Models.ViewModels.SalesOrderItemViewModel>;
    totalAmount: any;
    shippingAmount: any;
    totalDiscountAmount: any;
    totalCost: any;
    salesChannels: any;
    platformUrl: string | null;
  };
  'App.Http.ViewModels.Customer.SalesOrderViewModel': {
    id: string;
    customer: App.Models.ViewModels.Customer.CustomerViewModel;
    status: string | null;
    paymentMethod: string | null;
    shippingMethod: string | null;
    orderNumber: string;
    orderDate: Array<any>;
    deliveryAge: number;
    platformIntegrationDriver: App.Models.ViewModels.PlatformIntegrationViewModel | null;
    currency: string;
    totalAmount: string;
    shippingAmount: string;
    totalDiscountAmount: string;
    totalCost: string;
    salesOrderItems:
      | Array<App.Http.ViewModels.Customer.SalesOrderItemViewModel>
      | Array<App.Http.ViewModels.Customer.CombinedSalesOrderItemViewModel>;
    metaData: { [key: string]: any };
  };
  'App.Models.ViewModels.SelectOptionViewModel': {
    label: string;
    value: string | number;
  };
  'App.Models.ViewModels.SepaStripePaymentMethodViewModel': {
    bankCode: string;
    last4: string;
  };
  'App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentCostsViewModel': {
    label: string;
    totalPaid: number;
    totalFree: number;
    paidAmount: string;
    costPrice: string;
    surcharges: string;
    totalCost: string;
    image: string | null;
  };
  'App.Models.ViewModels.ShipmentErrorViewModel': {
    exceptionCode: number | null;
    exception: string | null;
  };
  'App.Models.ViewModels.Customer.ShipmentErrorViewModel': {
    exceptionCode: number | null;
    exception: string | null;
  };
  'App.Models.ViewModels.ShipmentLineViewModel': {
    id: number;
    returnOrderItem: App.Models.ViewModels.Customer.ReturnOrderItemViewModel;
    quantity: number;
  };
  'App.Models.ViewModels.Customer.ShipmentLineViewModel': {
    id: number;
    returnOrderItem: App.Models.ViewModels.Customer.ReturnOrderItemViewModel;
    quantity: number;
  };
  'App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentNumbersViewModel': {
    period: string;
    announced: number;
    sent: number;
    enRoute: number;
    delivered: number;
    errors: number;
    delayed: number;
    returnedToSender: number;
  };
  'App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentRealtimeNumbersViewModel': {
    announced: number;
    sent: number;
    enRoute: number;
  };
  'App.Models.ViewModels.ShipmentStatusLogViewModel': {
    id: string;
    status: string;
    carrierStatusCode: string | null;
    createdAt: string;
    shortCreatedAt: string;
  };
  'App.Models.ViewModels.Customer.ShipmentStatusLogViewModel': {
    id: string;
    status: string;
    carrierStatusCode: string | null;
  };
  'App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentTimingsViewModel': {
    label: string;
    dropOffTime: string | null;
    transitTime: string | null;
    totalTime: string | null;
    image: string | null;
  };
  'App.Models.ViewModels.ShipmentViewModel': {
    instruction: any | null;
    id: string;
    shipmentLines: Array<App.Models.ViewModels.ShipmentLineViewModel>;
    trackingNumber: string;
    shippingMethod: App.Models.ViewModels.ShippingMethodViewModel;
    costPrice: string;
    shipmentErrors: any;
    statusBadge: { color: string; label: string; icon: string };
    shippingIntegration: App.Models.ViewModels.ShippingIntegrationViewModel;
    hasCustomsDocuments: boolean;
    customsDocuments: array<{ value: string; label: string }>;
    isPrinterless: boolean;
    statusLogs: Array<App.Models.ViewModels.ShipmentStatusLogViewModel>;
    trackingUrl: string | null;
    pickUpTimeSlot: string | null;
    logo: { name: string; imageSourceUrl: string } | null;
    isDelivered: boolean;
    hideTrackingTimeline: boolean;
    activeInvestigationRequestDate: string | null;
    canStartInvestigationRequest: boolean;
  };
  'App.Models.ViewModels.Customer.ShipmentViewModel': {
    instruction: any | null;
    id: string;
    shipmentLines: Array<App.Models.ViewModels.Customer.ShipmentLineViewModel>;
    trackingNumber: string;
    barcode: App.Http.ViewModels.Customer.BarcodeViewModel | null;
    shippingMethod: App.Models.ViewModels.Customer.ShippingMethodViewModel;
    shipmentErrors: any;
    shippingIntegration: App.Models.ViewModels.Customer.ShippingIntegrationViewModel;
    isPrinterless: boolean;
    showQrCode: boolean;
    showOpenLabelButton: boolean;
    statusLogs: any;
    trackingUrl: string | null;
    hasCustomsDocuments: boolean;
    customsDocuments: array<{ value: string; label: string }>;
    pickUpTimeSlot: string | null;
    logo: { name: string; imageSourceUrl: string } | null;
    returnAddress: App.Models.ViewModels.Customer.ReturnAddressViewModel | null;
  };
  'App.Http.ViewModels.Panel.Analytics.Shipping.ShippingAnalyticsViewModel': {
    filters: {
      from: string;
      to: string;
      carrier: string | null;
      from_country: string | null;
      to_country: string | null;
    };
    carriers: {
      [key: number]: App.Http.ViewModels.Panel.Analytics.FilterOptionViewModel;
    };
    fromCountries: {
      [key: number]: App.Http.ViewModels.Panel.Analytics.FilterOptionViewModel;
    };
    toCountries: {
      [key: number]: App.Http.ViewModels.Panel.Analytics.FilterOptionViewModel;
    };
    shipmentNumbers: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentNumbersViewModel;
    periodicalShipmentNumbersBarChart: App.Http.ViewModels.Panel.Analytics.BarChartViewModel;
    topShippingMethodsHorizontalBarChart: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel;
    topCarriersHorizontalBarChart: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel;
    topShippingMethodTypesHorizontalBarChart: App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel;
    topRoutes: array<{
      label: string;
      value: string;
      percentage: int;
      dropOffTime: string;
      transitTime: string;
      totalTime: string;
    }>;
    averageTimes: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentTimingsViewModel;
    averageTimesGlobalOffset: {
      dropOffTimePercentage: int;
      transitTimePercentage: int;
      totalTimePercentage: int;
    };
    averageTimesByCarrier: {
      [
      key: number
      ]: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentTimingsViewModel;
    };
    averageCosts: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentCostsViewModel;
    averageCostsByCarrier: {
      [
      key: number
      ]: App.Http.ViewModels.Panel.Analytics.Shipping.ShipmentCostsViewModel;
    };
    deliveryPredictionsRangeChart: App.Http.ViewModels.Panel.Analytics.RangeChartViewModel;
  };
  'App.Models.ViewModels.ShippingContractViewModel': {
    id: string;
    name: string;
    contractDetails: Array<any>;
    shippingIntegration: App.Models.ViewModels.ShippingIntegrationViewModel | null;
    isActivated: boolean;
    isOwnContract: boolean;
  };
  'App.Models.ViewModels.ShippingInstructionViewModel': {
    id: string;
    name: string;
    type: any | null;
    isPrimary: boolean;
    shippingInstructionLocales: any;
  };
  'App.Http.ViewModels.Panel.Settings.Templates.ShippingInstructions.Edit.ShippingInstructionViewModel': {
    id: string;
    name: string;
    type: any | null;
    isPrimary: boolean;
    shippingInstructionLocales: { [key: number]: any };
  };
  'App.Models.ViewModels.ShippingIntegrationViewModel': {
    id: string;
    name: string;
    logo: { name: string; imageSourceUrl: string };
    driver: App.Enums.ShippingIntegrationDriver;
  };
  'App.Models.ViewModels.Customer.ShippingIntegrationViewModel': {
    id: string;
    name: string;
    logo: { name: string; imageSourceUrl: string };
    driver: App.Enums.ShippingIntegrationDriver;
  };
  'App.Http.ViewModels.Customer.ShippingMethodOptionViewModel': {
    id: string;
    name: string;
    price: string | null;
    imageSrc: string | null;
    carrierName: string | null;
    returnLocation: App.Models.ViewModels.Customer.ReturnAddressViewModel | null;
    type: App.Enums.ShippingMethodType;
    isPrinterless: boolean;
    countryId: string | null;
    supportsInsurance: boolean;
  };
  'App.Models.ViewModels.ShippingMethodViewModel': {
    id: string;
    carrier: App.Models.ViewModels.CarrierViewModel | null;
    productCode: string | null;
    name: string;
    imageSrc: string | null;
    carrierName: string | null;
    fromCountry: App.Models.ViewModels.CountryViewModel;
    toCountry: App.Models.ViewModels.CountryViewModel;
    fromWeight: string | null;
    toWeight: string | null;
    fromWeightRaw: number;
    toWeightRaw: number;
    cost: string;
    maxWidth: string;
    maxHeight: string;
    maxLength: string;
    maxWidthRaw: number | null;
    maxHeightRaw: number | null;
    maxLengthRaw: number;
    minWidth: string;
    minHeight: string;
    minLength: string;
    minWidthRaw: number;
    minHeightRaw: number;
    minLengthRaw: number;
    customIntegrationUrl: string | null;
    shippingIntegration: App.Models.ViewModels.ShippingIntegrationViewModel;
    type: App.Enums.ShippingMethodType;
    isPrinterless: boolean;
  };
  'App.Models.ViewModels.Customer.ShippingMethodViewModel': {
    id: string;
    carrier: App.Models.ViewModels.Customer.CarrierViewModel | null;
    name: string;
    imageSrc: string | null;
    carrierName: string | null;
    type: App.Enums.ShippingMethodType;
    isPrinterless: boolean;
  };
  'App.Models.ViewModels.ShippingSettingsViewModel': {
    defaultHsCode: string | null;
    defaultCountryOfOriginId: string | null;
    vatNumber: string | null;
    eoriNumber: string | null;
  };
  'App.Http.ViewModels.Panel.Auth.ShowFinishExpressRegisterViewModel': {
    postUrl: string;
  };
  'App.Http.ViewModels.Panel.Account.ShowInviteViewModel': {
    user: App.Models.ViewModels.UserViewModel;
    tenant: App.Models.ViewModels.TenantViewModel;
    invitation: App.Models.ViewModels.InvitationViewModel;
  };
  'App.Http.ViewModels.Customer.ShowOrderStatusViewModel': {
    returnNumber: string;
    salesOrderNumber: string;
    customerName: string | null;
    customerEmail: string | null;
    orderCreatedAt: string;
    answers: any;
    returnOrderIntent: App.Models.ViewModels.Customer.ReturnOrderIntentViewModel;
    returnOrderState: App.Enums.ReturnOrderState;
    shipments: Array<App.Models.ViewModels.Customer.ShipmentViewModel>;
    returnOrderItems: Array<App.Models.ViewModels.Customer.ReturnOrderItemViewModel>;
    notes: Array<App.Models.ViewModels.Customer.NoteViewModel>;
    statuses: Array<
      | App.Models.ViewModels.Customer.ReturnStatusViewModel
      | App.Models.ViewModels.Customer.RequestStatusViewModel
    >;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<string>;
    addressData: Array<any>;
    countryName: string;
    defaultReturnInstruction: App.Models.ViewModels.Customer.ReturnInstructionLocaleViewModel | null;
    returnInstruction: App.Models.ViewModels.Customer.ReturnInstructionLocaleViewModel | null;
    isPrintReturnFormEnabled: boolean;
    canSplitOrder: boolean;
    createAnotherReturnForOrderRoute: string | null;
    expectsShipments: boolean;
    state: App.Enums.ReturnOrder.ReturnOrderState;
    useNewReturnOrderStates: boolean;
  };
  'App.Http.ViewModels.Customer.ShowPaymentRequestPaymentMethodsViewModel': {
    paymentRequestId: string;
    price: string;
    paymentMethods: Array<App.Models.ViewModels.Customer.PaymentMethodViewModel>;
    selectedPaymentMethod: App.Models.ViewModels.Customer.PaymentMethodViewModel | null;
    paymentStatus: string | null;
  };
  'App.Http.ViewModels.Customer.ShowPaymentRequestViewModel': {
    id: string;
    description: string;
    selectedPaymentRequestLineIds: Array<string>;
    paymentRequestLines: Array<App.Http.ViewModels.Customer.PaymentRequestLineViewModel>;
    currencyCode: string;
    isoCode: string;
  };
  'App.Http.ViewModels.Panel.Requests.Detail.ShowRequestOrderViewModel': {
    returnOrder: App.Http.ViewModels.Panel.Requests.Detail.RequestOrderViewModel;
    customerAddress: App.Models.ViewModels.CustomerAddressViewModel;
    form: App.Models.ViewModels.FormViewModel;
    returnOrderItems: Array<App.Http.ViewModels.Panel.Requests.Detail.RequestOrderItemViewModel>;
    requestStatuses: Array<App.Models.ViewModels.RequestStatusViewModel>;
    returnOrderEvents: Array<App.Models.ViewModels.ReturnOrderEventViewModel>;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<any>;
    requestNoteTemplates: Array<App.Models.ViewModels.NoteTemplateViewModel>;
    paymentRequestsNoteTemplates: Array<App.Models.ViewModels.NoteTemplateViewModel>;
    returnAddressOptions: Array<any>;
    returnAddress: App.Models.ViewModels.ReturnAddressViewModel | null;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    shipmentTypes: { [key: string]: string };
    paymentRequests: Array<App.Models.ViewModels.PaymentRequestViewModel>;
    metadata: Array<Array<any>>;
    compatibleSalesOrderIds: Array<string>;
  };
  'App.Http.ViewModels.Panel.Returns.Detail.ShowReturnOrderViewModel': {
    currency: string;
    returnOrder: App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderViewModel;
    customerAddress: App.Models.ViewModels.CustomerAddressViewModel;
    returnAddress: App.Models.ViewModels.ReturnAddressViewModel | null;
    form: App.Models.ViewModels.FormViewModel;
    returnOrderItems: Array<App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderItemViewModel>;
    returnStatuses: Array<App.Models.ViewModels.ReturnStatusViewModel>;
    integrationTraits: any;
    returnOrderEvents: Array<App.Models.ViewModels.ReturnOrderEventViewModel>;
    attachmentFileSizeLimit: number;
    attachmentFileTypes: Array<any>;
    returnNoteTemplates: Array<App.Models.ViewModels.NoteTemplateViewModel>;
    paymentRequestsNoteTemplates: Array<App.Models.ViewModels.NoteTemplateViewModel>;
    applyToShipping: boolean;
    enableFreeShipping: boolean;
    coupons: Array<App.Models.ViewModels.CouponViewModel>;
    refunds: Array<App.Models.ViewModels.RefundViewModel>;
    paymentTransactions: Array<App.Models.ViewModels.PaymentTransactionViewModel>;
    paymentRequests: Array<App.Models.ViewModels.PaymentRequestViewModel>;
    credentialMapping: any;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    metadata: Array<any>;
    externalReturns: Array<any>;
    compatibleSalesOrderIds: Array<string>;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.ShowReturnQuestionViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    returnQuestion: App.Models.ViewModels.ReturnQuestionViewModel;
    availableFieldTypes: Array<string>;
    conditionTypeMap: Record<string, string>;
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    returnOrderQuestions: { [key: string]: { [key: string]: any } };
    returnOrderItemQuestions: { [key: string]: { [key: string]: any } };
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    availableConditionTypesByLevel: Record<string, string | object>;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    associatedReturnRules: any;
    associatedReturnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.ShowReturnRuleViewModel': {
    form: App.Models.ViewModels.FormViewModel;
    returnRule: App.Models.ViewModels.ReturnRuleViewModel;
    returnReasons: Array<App.Models.ViewModels.ReturnReasonViewModel>;
    conditionTypeMap: { [key: string]: string };
    availableConditionTypes: { [key: string]: string };
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    locales: Array<App.Models.ViewModels.LocaleViewModel>;
    defaultLocale: App.Models.ViewModels.LocaleViewModel;
    tags: Array<App.Models.ViewModels.TagViewModel>;
    returnInstructions: Array<App.Models.ViewModels.ReturnInstructionViewModel>;
    actionOptions: { [key: number]: { [key: string]: any } };
    returnAddressesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    returnStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    requestStatusesOptions: Array<App.Models.ViewModels.SelectOptionViewModel>;
    nonApplicableConditionFields: { [key: number]: string };
    returnQuestions: any;
  };
  'App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.ShowShippingContractViewModel': {
    shippingContract: App.Models.ViewModels.ShippingContractViewModel;
    formStructure: { [key: string]: any };
    settings: { [key: string]: any };
    countries: Array<App.Models.ViewModels.CountryViewModel>;
    showUpdateButton: boolean;
    shippingContracts: Array<any>;
  };
  'App.Models.ViewModels.StatusBadgeViewModel': {
    label: string;
    color: string | null;
  };
  'App.Http.ViewModels.Customer.StoreLocationViewModel': {
    id: string;
    name: string;
    completeAddress: string;
    imageSrc: string | null;
    url: string;
    distanceLabel: string | null;
  };
  'App.Models.ViewModels.StripePaymentMethodViewModel': {
    id: string;
    isDefaultMethod: boolean;
    type: string;
  };
  'App.Models.ViewModels.SubscriptionPlanViewModel': {
    id: string;
    name: string;
    description: string;
    subscriptionProduct: App.Models.ViewModels.SubscriptionProductViewModel | null;
    sortOrder: number;
  };
  'App.Models.ViewModels.SubscriptionProductViewModel': {
    id: string;
    name: string;
    price: { amount: number; unit: string; label: string };
    annualPrice: { amount: number; unit: string; label: string };
    priceId: string | null;
    volumeIncluded: number;
    subscriptionPeriod: string;
    showWarningWhenNewSubscriptionPeriodDoesNotStartImmediately: boolean;
    isSubscribedToProduct: boolean;
    subscriptionPlan: App.Models.ViewModels.SubscriptionPlanViewModel | null;
    billingCycle: App.Enums.BillingCycle;
  };
  'App.Models.ViewModels.SubscriptionToggleViewModel': {
    label: string;
    value: string | number | null;
    badge: App.Models.ViewModels.StatusBadgeViewModel | null;
  };
  'App.Models.ViewModels.TagViewModel': {
    id: string;
    name: string;
    color: string;
    description: string | null;
  };
  'App.Http.ViewModels.Panel.Account.TeamViewModel': {
    owner: App.Models.ViewModels.UserViewModel;
    hasActiveOwnershipTransfer: boolean;
    ownershipTransferRequests: Array<App.Models.ViewModels.OwnershipTransferRequestViewModel>;
    isTenantOwner: boolean;
    canEditPermissions: boolean;
    ownershipTransferableUsers: Array<App.Models.ViewModels.MemberViewModel>;
    members: Array<any>;
    selectedResource:
      | App.Models.ViewModels.UserViewModel
      | App.Models.ViewModels.InvitationViewModel
      | null;
    permissions: any | null;
    isEditingPermissions: boolean;
    inviteInputPlaceholderText: string;
  };
  'App.Models.ViewModels.TenantDocumentViewModel': {
    id: string;
    createdAt: string;
    title: string;
    fileName: string;
  };
  'App.Models.ViewModels.TenantViewModel': {
    id: string;
    name: string;
    country: App.Models.ViewModels.CountryViewModel | null;
    invoiceEmail: string | null;
    street: string | null;
    postcode: string | null;
    city: string | null;
    partnerVerificationCode: string;
    poNumber: string | null;
    vatNumber: string | null;
    isCustomer: boolean;
    limitAccess: App.Enums.PaywallAccessType | null;
    taxVerificationState: string;
    hasDefaultPaymentMethod: boolean;
    hasActiveSubscription: boolean;
    isOnGracePeriod: boolean;
    gracePeriod: string | null;
    hasEnabledPartnerAccess: boolean;
    isPartner: boolean;
    hasCreditcardPaymentEnabled: boolean;
    hasMarketingPopupAnswered: boolean;
    isInvoiceDetailsComplete: boolean;
  };
  'App.Http.ViewModels.Panel.Account.PartnerManagement.TenantViewModel': {
    id: string;
    name: string;
    poNumber: string | null;
    recentReturnOrderCount: number | null;
    differanceLastPeriod: number | null;
  };
  'App.Models.ViewModels.TranslationLanguageViewModel': {
    value: string;
    label: string;
  };
  'App.Models.ViewModels.UserPreferenceViewModel': {
    locale: string | null;
    currency: string | null;
    dateFormat: string | null;
    barcodeRedirectTo: string | null;
    notesVisibleToCustomerByDefault: boolean | null;
  };
  'App.Models.ViewModels.UserViewModel': {
    id: string;
    name: string;
    email: string;
    language: string;
    timezone: string;
    profilePhotoUrl: string;
    hasTwoFactorEnabled: boolean;
    isOwner: boolean;
    permissions: Array<string>;
  };
  'App.Http.ViewModels.Panel.Settings.WebhookInstructionViewModel': {
    instruction: string;
    urls: { [key: string]: string };
    documentationLink: string | null;
    warning: string | null;
  };
  'App.Models.ViewModels.WebhookSubscriptionViewModel': {
    id: string;
    endpoint: string;
    description: string | null;
    isEnabled: boolean;
    testMode: boolean;
    events: Array<string>;
  };
};
