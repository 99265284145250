<script lang="ts" setup>
import {
  computed,
} from 'vue';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { HelpText } from '../../HelpText';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },

  value: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    default: null,
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    required: true,
  },

  imageUrl: {
    type: String,
    default: null,
  },

  helpText: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const elementId = computed(() => props.id || useUniqueId('searchField'));

const checked = computed(() => {
  return props.modelValue === props.value;
});

const onRadioButtonChange = (): void => {
  if (!props.disabled) {
    emit('update:modelValue', props.value);
  }
};
</script>

<template>
  <div
    class="w-fit cursor-pointer flex items-center space-x-2 text-sm"
    @click="onRadioButtonChange"
  >
    <input
      :id="elementId"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      class="cursor-pointer accent-blue-500 focus:outline-none"
    >

    <img
      v-if="imageUrl"
      class="w-6"
      :src="imageUrl"
      loading="lazy"
    >

    <label
      class="block w-fit cursor-pointer text-sm text-slate-700 tracking-normal"
      :label="label"
      :for="elementId"
    >{{ label }}</label>

    <HelpText
      v-if="helpText"
      flush
    >
      {{ helpText }}
    </HelpText>
  </div>
</template>
