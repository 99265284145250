<script lang="ts" setup>
import { computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
  color: {
    type: String,
    default: null,
  },

  open: {
    type: Boolean,
    default: true,
  },

  closeable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const textColorClass = computed(() => {
  return `text-${props.color}-800`;
});

const classList = computed(() => {
  return [
    textColorClass.value,
    `bg-${props.color}-100`,
    `hover:bg-${props.color}-200`,
  ];
});

const closeBadge = () => {
  emit('close');
};
</script>

<template>
  <span
    v-if="open"
    class="inline-flex items-center px-2 text-xs leading-5 font-medium rounded-md"
    :class="classList"
  >
    <span>
      <slot />
    </span>

    <button
      v-if="closeable"
      type="button"
      class="shrink-0 ml-1 rounded-full inline-flex items-center justify-center hover:opacity-40"
      @click.stop="closeBadge"
    >
      <XMarkIcon
        v-if="closeable"
        class="h-3 w-3"
        :class="textColorClass"
      />
    </button>
  </span>
</template>
