<script lang="ts" setup>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  useSlots,
} from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

const {
  icon = null,
  open = false,
  title = null,
  titleAlignment = 'center',
} = defineProps<{
  icon?: string | null;
  open?: boolean;
  title?: string | null;
  titleAlignment?: 'center' | 'left';
}>();

const emit = defineEmits<{ (e: 'close') }>();

function close(): void {
  emit('close');
}

const modal = ref<HTMLElement | null>(null);

function closeModalOnEsc(e): void {
  if (e.key === 'Escape') {
    close();
  }
}

onMounted(() => {
  document.addEventListener('keydown', closeModalOnEsc);

  if (modal.value) {
    modal.value.focus();
  }
});

onUnmounted(() => document.removeEventListener('keydown', closeModalOnEsc));

const slots = useSlots();

const hasFooter = computed<boolean>(() => !!slots['footer']);
</script>

<template>
  <Teleport to="body">
    <Transition name="nested">
      <div
        v-if="open"
        class="fixed inset-0 z-10 flex min-h-full items-end justify-center overflow-y-auto overflow-x-hidden sm:items-center"
      >
        <div
          class="background fixed inset-0 bg-slate-500/75"
          @click="close"
        />
        <div
          data-test="modal"
          class="modal w-h-full relative flex max-h-[60vh] w-full flex-col rounded-xl bg-white p-4 shadow-xl sm:min-h-0 sm:max-w-xl"
        >
          <XMarkIcon
            class="absolute right-0 top-0 z-10 mr-3 mt-3 size-5 cursor-pointer fill-slate-500 hover:fill-slate-700"
            @click="close"
          />

          <div
            v-if="icon || title"
            class="flex flex-col space-y-5 shrink-0"
            :class="{ 'items-center': titleAlignment === 'center' }"
          >
            <img
              v-if="icon"
              :src="icon"
              class="w-14 object-contain"
              loading="lazy"
            >

            <h3
              v-if="title"
              class="text-lg font-medium leading-6 text-slate-900"
            >
              {{ title }}
            </h3>
          </div>

          <div class="flex-1 overflow-auto sm:py-4">
            <slot />
          </div>

          <div
            v-if="hasFooter"
            class="shrink-0 p-2"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.nested-enter-active, .nested-leave-active {
  transition: all .2s ease-out;
}

.nested-enter-active .background, .nested-leave-active .background {
  transition: all .2s linear;
}

.nested-enter-from .background, .nested-leave-to .background {
  opacity: 0;
}

.nested-enter-active .modal, .nested-leave-active .modal {
  transition: all .1s ease-out;
}

@media screen and (max-width: 640px) {
  .nested-enter-from .modal, .nested-leave-to .modal {
    transform: translateY(110%);
  }
}

@media screen and (min-width: 640px) {
  .nested-enter-from .modal, .nested-leave-to .modal {
    opacity: 0;
  }
}
</style>
