<script lang="ts" setup>
import { computed, watch } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { ToastAction, ToastVariant } from '../types';
import ToastContent from './ToastContent.vue';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/outline';

const props = withDefaults(
  defineProps<{
    message?: string;
    open?: boolean;
    title?: string;
    variant?: ToastVariant;
    duration?: number;
    action?: ToastAction;
  }>(), {
    message: null,
    open: false,
    title: null,
    variant: ToastVariant.Success,
    duration: null,
    action: null,
  },
);

const emit = defineEmits<{
  close: [];
}>();

const closeToast = () => {
  emit('close');
};

const animationClass = computed(() => {
  return props.open ? 'show' : 'hide';
});

const iconData = computed(() => {
  switch (props.variant) {
    case ToastVariant.Info:
      return [InformationCircleIcon, 'blue'];

    case ToastVariant.Warning:
      return [ExclamationTriangleIcon, 'yellow'];

    case ToastVariant.Critical:
      return [ExclamationCircleIcon, 'red'];

    case ToastVariant.Success:
    default:
      return [CheckCircleIcon, 'green'];
  }
});

const iconName = computed(() => iconData.value[0]);
const iconColorClass = computed(() => `text-${iconData.value[1]}-600`);

watch(() => props.open, async (newValue) => {
  if (newValue === true && props.duration && props.duration > 0) {
    setTimeout(() => closeToast(), props.duration);
  }
}, { immediate: true });
</script>

<template>
  <div
    data-test="toast"
    class="relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black/5 overflow-hidden"
    :class="animationClass"
  >
    <div class="p-4">
      <div class="flex items-start">
        <component
          :is="iconName"
          :class="['h-6', 'w-6', iconColorClass]"
        />

        <ToastContent
          :title="title"
          :message="message"
          :action="action"
          @close="closeToast"
        />

        <div
          class="ml-4 shrink-0 flex"
          @click="closeToast"
        >
          <XMarkIcon class="h-5 w-5 rounded-md inline-flex text-slate-400 hover:text-slate-500 focus:outline-none cursor-pointer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.show {
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.hide {
  opacity: 1;
  animation: fadeOut 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(10%);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
    height: 0;
  }
}
</style>
