<script lang="ts" setup>
import {
  Heading,
  HeadingElement,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/customer/Components';
import { type Types } from '@app/shared/types/generated-v2';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

type ShowOrderStatusViewModel = Types['App.Http.ViewModels.Customer.ShowOrderStatusViewModel'];
type ReturnOrderState = ShowOrderStatusViewModel['returnOrderState'];

const { t } = useI18n();

const props = defineProps<{
  defaultReturnInstruction: ShowOrderStatusViewModel['defaultReturnInstruction'];
  returnInstruction: ShowOrderStatusViewModel['returnInstruction'];
  returnOrderState: ReturnOrderState;
  returnNumber: string;
  salesOrderNumber: string;
  orderCreatedAt: string;
  formLocale: Types['App.Models.ViewModels.Customer.FormLocaleViewModel'];
}>();

const returnInstructionTitle = computed<string>(() => {
  if (!props.returnInstruction?.title) {
    return props.defaultReturnInstruction?.title
      ?? t(`customer.status:return-order-state:${props.returnOrderState}:title`);
  }

  return props.returnInstruction.title;
});

const returnInstructionContent = computed<string>(() => {
  if (!props.returnInstruction?.content) {
    return props.defaultReturnInstruction?.content
      ?? t(`customer.status:return-order-state:${props.returnOrderState}:description`);
  }

  return props.returnInstruction.content;
});
</script>

<template>
  <div class="flex flex-col space-y-6 2xl:flex-row 2xl:justify-between 2xl:space-x-8 2xl:space-y-0">
    <div class="flex flex-1 flex-col space-y-6 2xl:flex-row 2xl:items-center 2xl:space-x-5 2xl:space-y-0">
      <div class="max-w-lg">
        <Heading :element="HeadingElement.H2">
          <TextStyle
            class="!text-2xl font-semibold"
            :shade="TextStyleShade.Dark"
          >
            {{ returnInstructionTitle }}
          </TextStyle>
        </Heading>
        <p class="mt-2 leading-5">
          <TextStyle
            class="!text-base "
            v-html="returnInstructionContent"
          />
        </p>
      </div>
    </div>
    <div class="2xl:self-center 2xl:text-right">
      <TextContainer :spacing="TextContainerSpacing.ExtraTight">
        <TextStyle
          :shade="TextStyleShade.Subdued"
          no-wrap
          strong
        >
          <i18n-t
            keypath="customer.status:return-reference"
            for="customer.status:return-reference"
          >
            <template #returnReference>
              <span class="font-normal">{{ returnNumber }}</span>
            </template>
          </i18n-t>
        </TextStyle>
        <TextStyle
          :shade="TextStyleShade.Subdued"
          no-wrap
          strong
        >
          <span>{{ formLocale.orderFieldLabel }}: </span>
          <span class="font-normal">{{ salesOrderNumber }}</span>
        </TextStyle>
        <TextStyle
          :shade="TextStyleShade.Subdued"
          no-wrap
          strong
        >
          <i18n-t
            keypath="customer.status:return-date"
            for="customer.status:return-date"
          >
            <template #returnDate>
              <span class="font-normal">
                {{ orderCreatedAt }}
              </span>
            </template>
          </i18n-t>
        </TextStyle>
      </TextContainer>
    </div>
  </div>
</template>
