<script lang="ts" setup>
import { ProgressStepStyle } from './types';
import { BuildingStorefrontIcon, CheckIcon, ClockIcon, QrCodeIcon, TruckIcon } from '@heroicons/vue/24/outline';
import { App } from '@app/shared/types/generated-v2';
import { computed } from 'vue';

type ReturnOrderState = App.Enums.ReturnOrder.ReturnOrderState;

const states = App.Enums.ReturnOrder.ReturnOrderState;

const props = defineProps<{
  style: ProgressStepStyle;
  state: ReturnOrderState;
}>();

const icon = computed(() => {
  if (props.state === states.REQUEST_PENDING) return ClockIcon;
  if (props.state === states.REQUEST_APPROVED) return QrCodeIcon;
  if (props.state === states.RETURN_SHIPPED) return TruckIcon;
  if (props.state === states.RETURN_ANNOUNCED) return BuildingStorefrontIcon;
  if (props.state === states.RETURN_PROCESSING) return CheckIcon;
  return null;
});
</script>

<template>
  <div
    class="grid size-12 place-items-center rounded-full border-4"
    :class="{
      'border-transparent bg-[var(--customer-background)] text-[var(--customer-foreground)]': style === 'filled',
      'border-[var(--customer-background)] bg-white text-slate-500': style === 'active',
      'border-slate-200 bg-white text-slate-500': style === 'pending',
    }"
  >
    <span
      class="size-6"
    >
      <component :is="icon" />
    </span>
  </div>
</template>
