<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  Form,
  Heading,
} from '@app/customer/Components';
import { AppLayout } from '@app/customer/Layouts';
import { ShippingMethodOption } from '@app/customer/Partials/ShippingMethodOption';
import { InsurancePackage } from '@app/customer/Partials/InsurancePackage';
import { useForm, usePage } from '@inertiajs/vue3';
import { PickupTimeslot } from '@app/customer/Partials/PickupTimeslot';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { computed, onMounted, ref, watch } from 'vue';
import { Link } from '@app/panel/Components';
import { useApi } from '@app/customer/Composables/useApi';
import { useI18n } from 'vue-i18n';
import StoreLocationOption from '@app/customer/Partials/ShippingMethodOption/components/StoreLocationOption.vue';

type ListShippingMethodsViewModel = Types['App.Http.ViewModels.Customer.ListShippingMethodsViewModel'];
type InsurancePackageViewModel = Types['App.Models.ViewModels.Customer.InsurancePackageViewModel'];
type ShippingMethodOptionViewModel = Types['App.Http.ViewModels.Customer.ShippingMethodOptionViewModel'];

const props = defineProps<ListShippingMethodsViewModel>();

const form = useForm<{
  form_shipping_method: string | null;
  insurance_package: string | null;
  is_pickup: boolean;
  time_slot_identifier: string;
}>({
  form_shipping_method: null,
  insurance_package: null,
  is_pickup: false,
  time_slot_identifier: '',
});

const selectedShippingMethodOption = computed<null | ShippingMethodOptionViewModel>(() => {
  return props.shippingMethodOptions.find((shippingMethodOption) => shippingMethodOption.id === form.form_shipping_method) ?? null;
});
const timeSlots = ref([]);
const timeSlotsLoading = ref(false);

const { t } = useI18n();

const supportEmail = computed(() => usePage().props.formLocale.replyToAddress || t('customer.shipping:fallback-for-support-email'));

onMounted(() => {
  const previousSelectedFormShippingMethod = props.shippingMethodOptions.find((shippingMethodOption) => shippingMethodOption.id === props.selectedFormShippingMethodId);
  form.form_shipping_method = previousSelectedFormShippingMethod?.id
  ?? props.returnInStoreShippingMethod?.id
  ?? props.shippingMethodOptions[0]?.id
  ?? null;

  const previousInsurancePackage = props.insurancePackages.find((insurancePackage) => insurancePackage.id === props.selectedInsurancePackageId);
  form.insurance_package = previousInsurancePackage?.id ?? null;
});

watch(() => form.form_shipping_method, () => {
  timeSlots.value = [];

  selectedTimeslot.value = null;
  form.time_slot_identifier = '';
  form.is_pickup = false;

  const selectShippingMethod = props.shippingMethodOptions.find((shippingMethodOption) => shippingMethodOption.id === form.form_shipping_method);

  if (!selectShippingMethod) {
    return;
  }

  switch (selectShippingMethod.type) {
    case App.Enums.ShippingMethodType.PickUp:
      fetchTimeslots(selectShippingMethod);
      break;
  }
});

function fetchTimeslots(selectShippingMethod: ShippingMethodOptionViewModel) {
  timeSlotsLoading.value = true;

  useApi().get(route('api.intent.time-slots', [
    props.returnOrderIntentId,
    selectShippingMethod.id,
  ])).then((response) => {
    timeSlots.value = response.data.data;

    // When there are timeslots found, is_pickup should be true
    if (timeSlots.value.length) {
      form.is_pickup = true;
    }
  }).finally(() => {
    timeSlotsLoading.value = false;
  });
}

const shouldShowInsurancePackages = computed<boolean>(() => {
  if (
    selectedShippingMethodOption.value === null
    || !selectedShippingMethodOption.value.supportsInsurance
    || selectedShippingMethodOption.value.id === props.returnInStoreShippingMethod?.id
  ) {
    return false;
  }

  return props.insurancePackages.length > 0 && form.form_shipping_method !== null;
});

function submitForm() {
  // Do not submit insurance package if it's not shown
  if (!shouldShowInsurancePackages.value) {
    form.insurance_package = null;
  }

  form.post(route('customer.intent.shipping-method.update', props.returnOrderIntentId), {
    preserveScroll: true,
  });
}

function setShippingMethod(shippingMethodOptionUuid: string) {
  form.form_shipping_method = shippingMethodOptionUuid;
}

function setInsurancePackage(insurancePackage: null | InsurancePackageViewModel) {
  form.insurance_package = insurancePackage?.id ?? null;
}

const selectedTimeslot = ref<number>(null);

function selectTimeSlot(timeSlot, key): void {
  selectedTimeslot.value = key;

  form.is_pickup = true;
  form.time_slot_identifier = timeSlot.identifier;
}

const buttonColor = useBrandColor();
</script>

<template>
  <AppLayout>
    <div class="max-w-3xl mx-auto">
      <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between border-b border-slate-100 pb-6 mb-4">
        <Heading>
          {{ $t('customer.shipping:title') }}
        </Heading>
      </div>

      <div
        v-if="form.errors.form_shipping_method"
        class="mb-6"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ form.errors.form_shipping_method }}
        </Banner>
      </div>

      <Form @submit="submitForm">
        <div
          v-if="shippingMethodOptions.length || (returnInStoreShippingMethod !== null)"
          class="space-y-10"
        >
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <StoreLocationOption
              v-if="returnInStoreShippingMethod !== null"
              :return-in-store-shipping-method="returnInStoreShippingMethod"
              :is-selected="returnInStoreShippingMethod.id === form.form_shipping_method"
              @select="setShippingMethod"
            />
            <ShippingMethodOption
              v-for="shippingMethodOption in shippingMethodOptions"
              :key="shippingMethodOption.id"
              :shipping-method-option="shippingMethodOption"
              :is-selected="form.form_shipping_method === shippingMethodOption.id"
              @select="setShippingMethod"
            />
          </div>
        </div>

        <div v-if="!shippingMethodOptions.length && returnInStoreShippingMethod === null">
          <Banner :variant="BannerVariant.Critical">
            {{ $t('customer.shipping:no-shipping-methods', {
              email: supportEmail,
            }) }}
          </Banner>
        </div>

        <div
          v-if="timeSlots.length"
          class="mt-12 space-y-6"
        >
          <div class="border-b border-slate-100 pb-6 mb-4">
            <Heading>{{ $t('customer.shipping:time-slot:select') }}</Heading>
          </div>

          <Banner
            v-if="form.errors.time_slot_identifier"
            :variant="BannerVariant.Critical"
          >
            {{ form.errors.time_slot_identifier }}
          </Banner>

          <div class="grid grid-cols-1 gap-2">
            <PickupTimeslot
              v-for="(timeSlot, key) in timeSlots"
              :key="key"
              :label="timeSlot.label"
              :surcharge="timeSlot.surcharge"
              :formatted-surcharge="timeSlot.formatted_surcharge"
              :checked="selectedTimeslot === key"
              @select="selectTimeSlot(timeSlot, key)"
            />
          </div>
        </div>

        <div
          v-if="shouldShowInsurancePackages"
          class="mt-12 space-y-6"
        >
          <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between border-b border-slate-100 pb-6 mb-4">
            <Heading>
              {{ $t('customer.shipping:insurance:title') }}
            </Heading>
          </div>
          <div class="flex flex-col">
            <p class="text-sm">
              {{ $t('customer.shipping:insurance:description') }}
            </p>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
              <div
                v-for="index in [1,2,3,4]"
                :key="index"
                class="flex space-x-3 text-sm"
              >
                <CheckIcon class="w-5 h-5 text-green-500" />
                <span>{{ $t('customer.shipping:insurance:benefit-' + index) }}</span>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-2">
            <InsurancePackage
              :from-country="null"
              :shipping-method-option="null"
              :insurance-package="null"
              :checked="form.insurance_package === null"
              @select="setInsurancePackage(null)"
            />
            <InsurancePackage
              v-for="insurancePackage in insurancePackages"
              :key="insurancePackage.id"
              :from-country="fromCountry"
              :shipping-method-option="selectedShippingMethodOption"
              :insurance-package="insurancePackage"
              :checked="form.insurance_package === insurancePackage.id"
              @select="setInsurancePackage(insurancePackage)"
            />
          </div>

          <div class="text-xs font-mono text-gray-400 leading-5">
            <i18n-t
              keypath="customer.shipping:insurance:agreement"
              for="customer.shipping:insurance:agreement"
            >
              <template #terms>
                <Link
                  native
                  target="_blank"
                  href="https://www.returnless.com/insurance-terms-conditions"
                  class="text-gray-400 underline"
                >
                  {{ $t('customer.shipping:insurance:terms') }}
                </Link>
              </template>
            </i18n-t>
          </div>
        </div>

        <div class="mt-12 ml-auto">
          <ButtonGroup>
            <Button
              full-width
              :disabled="alreadyFinalized"
              :href="$route('customer.intent.address', returnOrderIntentId)"
            >
              {{ $t('customer.shipping:back-button') }}
            </Button>
            <Button
              :disabled="form.form_shipping_method === null"
              :color="buttonColor"
              full-width
              type="submit"
              :loading="form.processing"
            >
              {{ $t('customer.shipping:continue-button') }}
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </div>
  </AppLayout>
</template>
